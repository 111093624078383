import {
  addDays,
  format,
  formatISO,
  intervalToDuration,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  subDays,
} from "date-fns";

export class DateUtils {
  static IsValid(date: Date) {
    return date instanceof Date && !isNaN(date.valueOf());
  }

  static Today() {
    return new Date();
  }

  static TodayDate() {
    let today = this.Today();
    let todayDay = this.DateISO(today);
    return this.ParseDate(todayDay);
  }

  static Tomorrow() {
    return addDays(new Date(), 1);
  }

  static DaysBefore(i: number) {
    return subDays(new Date(), i);
  }

  static DaySame(a: Date, b: Date) {
    if (
      a.getDate() === b.getDate() &&
      a.getMonth() === b.getMonth() &&
      a.getFullYear() === b.getFullYear()
    ) {
      return true;
    } else {
      return false;
    }
  }

  static DaysLate(a: Date) {
    const today = this.Today();
    if (isBefore(today, a)) {
      return 0;
    } else {
      let between = intervalToDuration({ start: a, end: today });
      return between.days;
    }
  }

  static NotifyToday(notify: Date, question: Date) {
    return (
      this.DaySame(notify, question) || notify.getTime() > question.getTime()
    );
  }

  static Between(
    after: Date,
    before: Date,
    target: Date,
    includeEnds?: boolean
  ) {
    return (
      (isBefore(target, before) && isAfter(target, after)) ||
      (includeEnds === true &&
        (isSameDay(before, target) || isSameDay(after, target)))
    );
  }

  static Before(isThisBefore: Date, target: Date) {
    return isBefore(isThisBefore, target);
  }

  static GetTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static ShortDate(date: Date) {
    return format(date, "MMM d");
  }

  static DatePicker(date: Date) {
    return date.toISOString();
  }

  //Monday, August 20
  static DisplayDate(date: Date) {
    return format(date, "eeee, MMMM dd");
  }

  static DisplayMonth(monthNumber: number) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  static DisplayTime(date: Date) {
    let timezone = date
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[1];
    let time = format(date, "h.mm aaa");

    return `${time} ${timezone}`;
  }

  static DisplayTimeOnly(date: Date) {
    let time = format(date, "h.mm aaa");
    return `${time}`;
  }

  static DateISO(date: Date) {
    return format(date, "yyyy-MM-dd");
  }

  static ToISO(date: Date) {
    return formatISO(date);
  }

  static ParseDate(str: string) {
    return parseISO(str);
  } //2022-09-05
}
