import { useRef, useEffect } from "react";

export class Util {
  static copyText(text: string) {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }
  static makeid(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  static sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  static is<T>(o: any, sample: T, strict = false, recursive = true): o is T {
    if (o == null) return false;
    let s = sample as any;
    // If we have primitives we check that they are of the same type and that type is not object
    if (typeof s === typeof o && typeof o != "object") return true;

    //If we have an array, then each of the items in the o array must be of the same type as the item in the sample array
    if (o instanceof Array) {
      // If the sample was not an arry then we return false;
      if (!(s instanceof Array)) return false;
      let oneSample = s[0];
      let e: any;
      for (e of o) {
        if (!Util.is(e, oneSample, strict, recursive)) return false;
      }
    } else {
      // We check if all the properties of sample are present on o
      for (let key of Object.getOwnPropertyNames(sample)) {
        if (typeof o[key] !== typeof s[key]) return false;
        if (
          recursive &&
          typeof s[key] == "object" &&
          !Util.is(o[key], s[key], strict, recursive)
        )
          return false;
      }
      // We check that o does not have any extra prperties to sample
      if (strict) {
        for (let key of Object.getOwnPropertyNames(o)) {
          if (s[key] == null) return false;
        }
      }
    }

    return true;
  }
  static timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  static hashCode = (str: string, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };
}

export function useTraceUpdate(props: any) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps: any, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
}
