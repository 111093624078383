import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../common/state/hooks"
import { JournalSelector } from "../../common/state/store"
import { DateUtils } from "../../common/DateUtils"
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from "@mui/joy"
import { subDays } from "date-fns"
import { MIN_DATE } from "../../common/Consts"
import { useNavigate } from "react-router-dom"
import { DaysViewMode, ViewMode, createURL } from "../common/Navigate"
import { journalThunk } from "../../common/state/JournalThunk"

interface IQuestionView {
  startDate: Date
}
export const JournalListView = ({ startDate }: IQuestionView) => {
  let views = []
  let minDate = DateUtils.ParseDate(MIN_DATE)
  let today = new Date()
  for (var i = 0; i < 7; i++) {
    let displayDate = subDays(startDate, i)
    //IF BEFORE MIN DATE
    if (DateUtils.Before(displayDate, minDate)) {
      continue
    }
    //IF BEYOND TODAY
    if (DateUtils.Before(today, displayDate)) {
      continue
    }

    views.push(
      <JournalListItem
        key={DateUtils.DateISO(displayDate)}
        date={displayDate}
      />,
    )
  }

  return <Stack spacing={2}>{views}</Stack>
}

interface ItemView {
  date: Date
}
const JournalListItem = ({ date }: ItemView) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const dateISO = DateUtils.DateISO(date)
  const entry = useAppSelector(JournalSelector.entry(dateISO))
  const apiState = useAppSelector(JournalSelector.apiState(dateISO))

  useEffect(() => {
    if (apiState === undefined) {
      dispatch(journalThunk({ dateISO, replace: false }))
    }
  }, [apiState])

  const onClick = () => {
    navigate(
      createURL({
        dateISO,
        viewMode: ViewMode.PersonalJournal,
        daysViewMode: DaysViewMode.Day,
      }),
    )
  }

  if (!date || apiState === undefined) {
    return <div />
  }

  const { loading, error } = apiState
  let body
  if (loading) {
    body = (
      <Stack alignItems={"center"} padding={5}>
        <CircularProgress />
      </Stack>
    )
  } else if (error) {
    body = (
      <Alert color="danger">
        {error} <Link overlay underline="none" onClick={onClick}></Link>
      </Alert>
    )
  } else if (entry) {
    body = []
    for (let i = 0; i < entry.responses.length; i++) {
      body.push(
        <Card key={i + "entry"} variant="soft">
          <CardContent>
            <Link overlay underline="none" onClick={onClick}></Link>
            <Typography level={"title-md"}>{entry.prompts[i]}</Typography>
            <Typography level={"body-md"}>{entry.responses[i]}</Typography>
          </CardContent>
        </Card>,
      )
    }
  } else {
    body = (
      <Alert color="neutral">
        No Entry <Link overlay underline="none" onClick={onClick}></Link>
      </Alert>
    )
  }

  return (
    <Stack spacing={1}>
      <Typography level="h2" fontWeight={"sm"}>
        {DateUtils.DisplayDate(date)}
      </Typography>
      {/* <Card variant="soft">
        <CardContent>
          <React.Fragment>
            <Link overlay underline="none" onClick={onClick}></Link>
          </React.Fragment>
        </CardContent>
      </Card> */}
      {body}
    </Stack>
  )
}
