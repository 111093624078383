import {
  DialogTitle,
  DialogContent,
  Stack,
  Button,
  Alert,
  DialogActions,
  Modal,
  Input,
  FormLabel,
  ModalDialog,
  Typography,
  LinearProgress,
} from "@mui/joy"
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../common/state/hooks"
import { ApiUtil, TokenResponse } from "../common_not/ApiUtil"
import { UserApi } from "../common/api/UserApi"
import { CoreSelector } from "../common/state/store"
import { AppName } from "../common/Consts"

enum AuthType {
  Create = "Create",
  Login = "Login",
  Refreshing = "Refresh",
  Intro = "Intro",
}

export const AuthDialog = () => {
  const user = useAppSelector(CoreSelector.user)

  const [open, setOpen] = React.useState(true)
  const [type, setType] = React.useState(AuthType.Refreshing)

  useEffect(() => {
    if (user === undefined) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [user])

  const goToCreate = () => {
    setType(AuthType.Create)
  }

  const goToLogin = () => {
    setType(AuthType.Login)
  }

  const goToIntro = () => {
    setType(AuthType.Intro)
  }

  let body = <div />
  switch (type) {
    case AuthType.Intro:
      body = <IntroDialog goToCreate={goToCreate} />
      break
    case AuthType.Create:
      body = <CreateDialog goToLogin={goToLogin} />
      break
    case AuthType.Login:
      body = <LoginDialog goToCreate={goToCreate} />
      break
    case AuthType.Refreshing:
      return <RefreshDialog goToCreate={goToIntro} />
      break
  }

  return (
    <Modal open={open} hideBackdrop>
      <ModalDialog minWidth={600}>{body}</ModalDialog>
    </Modal>
  )
}

interface IntroProps {
  goToCreate: () => void
}
export const IntroDialog = ({ goToCreate }: IntroProps) => {
  return (
    <React.Fragment>
      <DialogTitle>Welcome to {AppName}</DialogTitle>
      <DialogContent>
        {AppName} is a daily journal with a social twist.
        <br />
        <br />
        Everyday a new shared question will be available. <br />
        If you answer the question, you'll be able to read other responses.
        <br />
        <br />
        {AppName} is a work in progress. Send your feedback and suggestions!
      </DialogContent>
      <Button onClick={goToCreate}>Let's Get Started</Button>
    </React.Fragment>
  )
}
interface CreateProps {
  goToLogin: () => void
}
export const CreateDialog = ({ goToLogin }: CreateProps) => {
  const dispatch = useAppDispatch()
  const [auth, setAuth] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [password, setPass] = useState("")

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let params = new URLSearchParams(window.location.hash)
    let paramToken = params.get("#invite")
    if (paramToken) {
      setAuth(paramToken)
    }
  }, [])

  const sendUsername = async () => {
    setError("")
    setLoading(true)
    const response = await ApiUtil.ApiWrap(
      UserApi.create(name, password, email, auth),
    )
    if (response.success === true) {
      dispatch(
        CoreActions.update({
          user: response.response.user,
          accessToken: response.response.token,
        }),
      )
    } else {
      setError(response.error)
    }
    setLoading(false)
  }

  const disableTextField = loading
  const disableButton = name.length < 1 || !email || loading

  let text = (
    <React.Fragment>
      <DialogContent>
        Create an account below. It will save your responses so you can go back
        to them any time.
      </DialogContent>
    </React.Fragment>
  )
  let input = (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <FormLabel>Invite Code</FormLabel>
        <Input
          disabled={disableTextField}
          onChange={(val) => setAuth(val.target.value)}
          value={auth}
          variant="outlined"
          placeholder="Any Duvalaki user can create invite codes for you to register"
        />
      </Stack>
      <Stack spacing={1}>
        <FormLabel>Contact Email</FormLabel>
        <Typography level="body-sm">
          Your contact email will only be used for announcements and updates
          regarding the service. <br />
          It will not be shared with anyone else.
        </Typography>
        <Input
          disabled={disableTextField}
          onChange={(val) => setEmail(val.target.value)}
          value={email}
          variant="outlined"
          placeholder="Best email to contact"
        />
      </Stack>
      <Stack spacing={1}>
        <FormLabel>Display Name</FormLabel>
        <Typography level="body-sm">
          Your display name will be shown next to your responses if you share
          them with others. <br />
          Please choose carefully if you wish to remain anonymous.
        </Typography>
        <Input
          disabled={disableTextField}
          onChange={(val) => setName(val.target.value)}
          value={name}
          variant="outlined"
          placeholder="A nickname or random thing"
        />
      </Stack>
      <Stack spacing={1}>
        <FormLabel>Password</FormLabel>
        <Input
          type="password"
          disabled={disableTextField}
          onChange={(val) => setPass(val.target.value)}
          value={password}
          variant="outlined"
          placeholder="A secret password"
        />
      </Stack>
    </Stack>
  )

  return (
    <React.Fragment>
      <DialogTitle>Welcome!</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Stack>{text}</Stack>
          {input}
          {error && <Alert color="danger">{error}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={goToLogin} variant="outlined" disabled={loading}>
          Returning User?
        </Button>
        <Button
          onClick={sendUsername}
          loading={loading}
          disabled={disableButton}
        >
          Let's Get Started
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

interface LoginProps {
  goToCreate: () => void
}
export const LoginDialog = ({ goToCreate }: LoginProps) => {
  const dispatch = useAppDispatch()
  const [name, setName] = useState("")
  const [code, setCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const tryLogin = async () => {
    setLoading(true)
    const response = await ApiUtil.ApiWrap(UserApi.login(name, code))
    if (response.success === true) {
      dispatch(
        CoreActions.update({
          user: response.response.user,
          accessToken: response.response.token,
        }),
      )
    } else {
      setError(response.error)
    }
    setLoading(false)
  }

  const loginDisabled = loading || !name || !code

  return (
    <React.Fragment>
      <DialogTitle>Welcome back!</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Stack>
            <DialogContent>
              Please enter your display name and the associated login code.
            </DialogContent>
          </Stack>
          <Stack spacing={1}>
            <FormLabel>Display Name</FormLabel>
            <Input
              autoFocus
              fullWidth
              onChange={(val) => setName(val.target.value)}
              value={name}
              variant="outlined"
              placeholder="Your display name"
            />
          </Stack>
          <Stack spacing={1}>
            <FormLabel>Password</FormLabel>
            <Input
              fullWidth
              type="password"
              onChange={(val) => setCode(val.target.value)}
              value={code}
              variant="outlined"
              placeholder="Use login code if you have not set a password"
            />
          </Stack>
          {error && <Alert color="danger">{error}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={goToCreate} disabled={loading} variant="outlined">
          New User?
        </Button>
        <Button
          onClick={() => tryLogin()}
          loading={loading}
          disabled={loginDisabled}
        >
          Login
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

import icon from "./../asset/icon.png"
import { useNavigate } from "react-router-dom"
import { CoreActions } from "../common/state/CoreRedux"
interface RefreshProps {
  goToCreate: () => void
}
export const RefreshDialog = ({ goToCreate }: RefreshProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const tryRefresh = async (tokenResponse: TokenResponse) => {
    if (tokenResponse.token == null || tokenResponse == undefined) {
      goToCreate()
      return
    }

    const response = await ApiUtil.ApiWrap(UserApi.refresh(tokenResponse.token))
    if (response.success === true) {
      dispatch(
        CoreActions.update({
          user: response.response.user,
          accessToken: response.response.token,
        }),
      )
    } else {
      goToCreate()
    }
  }

  useEffect(() => {
    let params = new URLSearchParams(window.location.hash)
    let paramToken = params.get("#token")
    if (paramToken) {
      tryRefresh({
        token: paramToken,
      })
      navigate(location.pathname)
    } else {
      let existingToken = ApiUtil.GetApiToken()
      tryRefresh(existingToken)
    }
  }, [])

  let body = (
    <Stack spacing={2}>
      <img src={icon} style={{ height: 100, width: 100 }} />
      <LinearProgress />
    </Stack>
  )

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {body}
    </div>
  )
}
