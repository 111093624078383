import { subDays } from "date-fns"
import { MIN_DATE } from "../../common/Consts"
import { DateUtils } from "../../common/DateUtils"
import { Alert, CircularProgress, Grid, Stack, Typography } from "@mui/joy"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { journalThunk } from "../../common/state/JournalThunk"
import { useAppDispatch, useAppSelector } from "../../common/state/hooks"
import { CampfireSelector, JournalSelector } from "../../common/state/store"
import { createURL, ViewMode, DaysViewMode } from "../common/Navigate"
import { questionThunk } from "../../common/state/CampfireThunk"

interface IBlockView {
  startDate: Date
  viewMode: ViewMode
}
export const MonthView = ({ startDate, viewMode }: IBlockView) => {
  let minDate = DateUtils.ParseDate(MIN_DATE)
  let today = new Date()

  let monthViews = new Map<number, React.ReactNode[]>()
  for (var i = 0; i < 30; i++) {
    let displayDate = subDays(startDate, i)
    //IF BEFORE MIN DATE
    if (DateUtils.Before(displayDate, minDate)) {
      continue
    }
    //IF BEYOND TODAY
    if (DateUtils.Before(today, displayDate)) {
      continue
    }

    let month = monthViews.get(displayDate.getMonth())
    if (month === undefined) {
      month = []
    }
    if (viewMode === ViewMode.DailyQuestion) {
      month.push(
        <QuestionBlockItem
          key={DateUtils.DateISO(displayDate)}
          date={displayDate}
        />,
      )
    } else {
      month.push(
        <JournalBlockItem
          key={DateUtils.DateISO(displayDate)}
          date={displayDate}
        />,
      )
    }
    monthViews.set(displayDate.getMonth(), month)
  }

  let views = []
  for (let [month, v] of monthViews) {
    views.push(
      <Stack key={month} spacing={1}>
        <Typography level="h2" fontWeight={"sm"}>
          {DateUtils.DisplayMonth(month)}
        </Typography>
        <Grid container spacing={1} sx={{ flexGrow: 1 }}>
          {v}
        </Grid>
      </Stack>,
    )
  }

  return <Stack spacing={2}>{views}</Stack>
}

interface ItemView {
  date: Date
}
const JournalBlockItem = ({ date }: ItemView) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const dateISO = DateUtils.DateISO(date)
  const entry = useAppSelector(JournalSelector.entry(dateISO))
  const apiState = useAppSelector(JournalSelector.apiState(dateISO))

  useEffect(() => {
    if (apiState === undefined) {
      dispatch(journalThunk({ dateISO, replace: false }))
    }
  }, [apiState])

  const onClick = () => {
    navigate(
      createURL({
        dateISO,
        viewMode: ViewMode.PersonalJournal,
        daysViewMode: DaysViewMode.Day,
      }),
    )
  }

  if (!date || apiState === undefined) {
    return <div />
  }

  const { loading, error } = apiState
  let body
  if (loading) {
    body = (
      <Alert>
        <Stack width={"100%"} justifyContent={"center"} direction={"row"}>
          <CircularProgress size="sm" />
        </Stack>
      </Alert>
    )
  } else if (error) {
    body = (
      <Alert color="danger">
        <Stack width={"100%"} justifyContent={"center"} direction={"row"}>
          <Typography>{date.getDate()}</Typography>
        </Stack>
      </Alert>
    )
  } else if (entry) {
    body = (
      <Alert color="success">
        <Stack width={"100%"} justifyContent={"center"} direction={"row"}>
          <Typography>{date.getDate()}</Typography>
        </Stack>
      </Alert>
    )
  } else {
    body = (
      <Alert color="neutral">
        <Stack width={"100%"} justifyContent={"center"} direction={"row"}>
          <Typography>{date.getDate()}</Typography>
        </Stack>
      </Alert>
    )
  }

  return (
    <Grid xs={3} onClick={onClick}>
      {body}
    </Grid>
  )
}

interface ItemView {
  date: Date
}
const QuestionBlockItem = ({ date }: ItemView) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const dateISO = DateUtils.DateISO(date)
  const question = useAppSelector(CampfireSelector.question(dateISO))
  const apiState = useAppSelector(CampfireSelector.questionApiState(dateISO))

  useEffect(() => {
    if (apiState === undefined) {
      dispatch(questionThunk({ dateISO, replace: false }))
    }
  }, [apiState])

  const onClick = () => {
    navigate(
      createURL({
        dateISO,
        viewMode: ViewMode.DailyQuestion,
        daysViewMode: DaysViewMode.Day,
      }),
    )
  }

  if (!date || apiState === undefined) {
    return <div />
  }

  const { loading, error } = apiState
  let body
  if (loading) {
    body = (
      <Alert>
        <Stack width={"100%"} justifyContent={"center"} direction={"row"}>
          <CircularProgress size="sm" />
        </Stack>
      </Alert>
    )
  } else if (error) {
    body = (
      <Alert color="danger">
        <Stack width={"100%"} justifyContent={"center"} direction={"row"}>
          <Typography>{date.getDate()}</Typography>
        </Stack>
      </Alert>
    )
  } else if (question) {
    if (question.response) {
      body = (
        <Alert color="success">
          <Stack width={"100%"} justifyContent={"center"} direction={"row"}>
            <Typography>{date.getDate()}</Typography>
          </Stack>
        </Alert>
      )
    } else {
      body = (
        <Alert color="warning">
          <Stack width={"100%"} justifyContent={"center"} direction={"row"}>
            <Typography>{date.getDate()}</Typography>
          </Stack>
        </Alert>
      )
    }
  } else {
    body = (
      <Alert color="neutral">
        <Stack width={"100%"} justifyContent={"center"} direction={"row"}>
          <Typography>{date.getDate()}</Typography>
        </Stack>
      </Alert>
    )
  }

  return (
    <Grid xs={3} onClick={onClick}>
      {body}
    </Grid>
  )
}
