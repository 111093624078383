import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/joy"
import { AppName } from "../../common/Consts"
import React from "react"
import { UserModel } from "../../common/SharedModel"

interface Props {
  user: UserModel
  onOpen: () => void
  onClose: () => void
}
export const AboutPanel = ({ onClose }: Props) => {
  return (
    <React.Fragment>
      <DialogTitle>About {AppName}</DialogTitle>
      <DialogContent>
        I used to write a lot. I wrote essays for school, love letters to my
        crushes, short stories for my friends, and some times the rare journal
        entry. <br /> <br />
        In recent years I haven't written much except for short messages over
        text and code. The longest things I've written are probably apologies
        after doing something stupid. In that time, I've forgotten that writing
        can be a powerful activity. It can give us the chance to introspect, to
        reflect, to process, and to create. <br /> <br />
        {AppName} is my attempt to rekindle the joy of writing for everyone. It
        is a work in progress and I look forward to hearing your feedback and
        suggestions.
        <br /> <br />
        Thank you for your time and I hope you find a question that resonates
        with you!
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <div />
      </DialogActions>
    </React.Fragment>
  )
}
