import {
  Button,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  DialogActions,
  Input,
  Alert,
  FormLabel,
} from "@mui/joy"
import React, { useEffect, useState } from "react"
import { ApiUtil } from "../../common_not/ApiUtil"
import { UserApi } from "../../common/api/UserApi"
import { useDispatch } from "react-redux"
import { UserModel } from "../../common/SharedModel"
import { CoreActions } from "../../common/state/CoreRedux"

interface Props {
  user: UserModel
  onOpen: () => void
  onClose: () => void
}
export const SetPasswordPanel = ({ user, onClose }: Props) => {
  const dispatch = useDispatch()
  const [loginCode, setLoginCode] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  useEffect(() => {
    setLoginCode("")
    setPassword("")
  }, [user])

  const onUpdateCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginCode(event.target.value)
  }
  const onUpdatePass = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const onClickSubmit = async () => {
    setError("")
    setLoading(true)
    const response = await ApiUtil.ApiWrap(
      UserApi.updatePassword(loginCode, password),
    )
    if (response.success === true) {
      dispatch(
        CoreActions.update({
          user: response.response.user,
          accessToken: response.response.token,
        }),
      )
      setSuccess("Password successfully set!")
    } else {
      setError(response.error)
    }
    setLoading(false)
  }

  if (success) {
    return (
      <React.Fragment>
        <DialogTitle>Set Password</DialogTitle>
        <DialogContent>
          <Alert color="success">{success}</Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            Close
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }

  const disable = !loginCode || !password

  return (
    <React.Fragment>
      <DialogTitle>Set Password</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <FormLabel>Login Code</FormLabel>
            <Typography level="body-sm">
              The code you received when you created your account. Contact{" "}
              {user.supportEmail} if you do not have it with your email and
              display name.
            </Typography>
            <Input
              autoFocus
              fullWidth
              onChange={onUpdateCode}
              value={loginCode}
              variant="outlined"
            />
          </Stack>
          <Stack spacing={1}>
            <FormLabel>Password</FormLabel>
            <Typography level="body-sm">
              You will use your password to log back in. The login code will be
              useless after you set your password.
            </Typography>
            <Input
              type="password"
              fullWidth
              onChange={onUpdatePass}
              value={password}
              variant="outlined"
            />
          </Stack>
          {error && <Alert color="danger">{error}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={loading}>
          Close
        </Button>
        <Button onClick={onClickSubmit} loading={loading} disabled={disable}>
          Update
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}
