import { useState } from "react"
import { ResponseDisplayModel } from "../../../common/SharedModel"
import { MoreHoriz } from "@mui/icons-material"
import {
  Dropdown,
  MenuButton,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  ModalDialog,
} from "@mui/joy"
import React from "react"
import { ReportPanel } from "./ReportPanel"
import { ResponseEditPanel } from "./ResponseEditPanel"
import { DailyQuestionReduxModel } from "../../../common/ModelExtended"

enum ResponseMenuType {
  EditResponse = 1,
  Report = 2,
}

interface Props {
  userResponse?: boolean
  question: DailyQuestionReduxModel
  response: ResponseDisplayModel
}
export const ResponseDropdown = ({
  userResponse,
  question,
  response,
}: Props) => {
  const [open, setOpen] = useState(false)
  const [menuType, setType] = useState(ResponseMenuType.Report)

  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)
  const onClick = (type: ResponseMenuType) => () => {
    setType(type)
    setOpen(true)
  }

  let body = (
    <ReportPanel question={question} response={response} onClose={onClose} />
  )
  switch (menuType) {
    case ResponseMenuType.EditResponse:
      body = (
        <ResponseEditPanel
          question={question}
          response={response}
          onClose={onClose}
        />
      )
      break
  }

  return (
    <React.Fragment>
      <Dropdown>
        <MenuButton slots={{ root: IconButton }}>
          <MoreHoriz />
        </MenuButton>
        <Menu>
          {userResponse ? (
            <MenuItem onClick={onClick(ResponseMenuType.EditResponse)}>
              Edit
            </MenuItem>
          ) : (
            <MenuItem onClick={onClick(ResponseMenuType.Report)}>
              Report
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog minWidth={600}>{body}</ModalDialog>
      </Modal>
    </React.Fragment>
  )
}
