import { useEffect } from "react"
import { questionThunk } from "../../common/state/CampfireThunk"
import { useAppDispatch, useAppSelector } from "../../common/state/hooks"
import { CampfireSelector } from "../../common/state/store"
import { DateUtils } from "../../common/DateUtils"
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/joy"
import { Paragraphs } from "../common/Paragraphs"
import { subDays } from "date-fns"
import { MIN_DATE } from "../../common/Consts"
import React from "react"
import { useNavigate } from "react-router-dom"
import { DaysViewMode, ViewMode, createURL } from "../common/Navigate"

interface IQuestionView {
  startDate: Date
}
export const QuestionListView = ({ startDate }: IQuestionView) => {
  let views = []
  let minDate = DateUtils.ParseDate(MIN_DATE)
  let today = new Date()
  for (var i = 0; i < 7; i++) {
    let displayDate = subDays(startDate, i)
    //IF BEFORE MIN DATE
    if (DateUtils.Before(displayDate, minDate)) {
      continue
    }
    //IF BEYOND TODAY
    if (DateUtils.Before(today, displayDate)) {
      continue
    }

    views.push(
      <QuestionListItem
        key={DateUtils.DateISO(displayDate)}
        date={displayDate}
      />,
    )
  }

  return <Stack spacing={2}>{views}</Stack>
}

interface ItemView {
  date: Date
}
const QuestionListItem = ({ date }: ItemView) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const dateISO = DateUtils.DateISO(date)
  const question = useAppSelector(CampfireSelector.question(dateISO))
  const apiState = useAppSelector(CampfireSelector.questionApiState(dateISO))

  useEffect(() => {
    if (apiState === undefined) {
      dispatch(questionThunk({ dateISO, replace: false }))
    }
  }, [apiState])

  const onClick = () => {
    navigate(
      createURL({
        dateISO,
        viewMode: ViewMode.DailyQuestion,
        daysViewMode: DaysViewMode.Day,
      }),
    )
  }

  if (!date || apiState === undefined) {
    return <div />
  }

  const { loading, error } = apiState
  let color: "neutral" | "warning" = "neutral"
  let body

  if (loading) {
    body = (
      <Stack alignItems={"center"} padding={5}>
        <CircularProgress />
      </Stack>
    )
  } else if (error) {
    body = <Alert color="danger">{error}</Alert>
  } else if (question) {
    if (question.response) {
      body = (
        <Stack spacing={2}>
          <Typography level="title-md">{question.questionText}</Typography>
          <Paragraphs
            text={
              question.response ? question.response.questionAnswer : "No Answer"
            }
          />
        </Stack>
      )
    } else {
      color = "warning"
      body = (
        <Stack spacing={2}>
          <Typography level="title-md">{question.questionText}</Typography>
          <Paragraphs text="No Answer" />
        </Stack>
      )
    }
  } else {
    return (
      <Stack spacing={1} onClick={onClick}>
        <Typography level="h2" fontWeight={"sm"}>
          {DateUtils.DisplayDate(date)}
        </Typography>
        <Alert color="neutral">No Question</Alert>
      </Stack>
    )
  }

  return (
    <Stack spacing={1} onClick={onClick}>
      <Typography level="h2" fontWeight={"sm"}>
        {DateUtils.DisplayDate(date)}
      </Typography>
      <Card variant="soft" color={color}>
        <CardContent>
          <React.Fragment>{body}</React.Fragment>
        </CardContent>
      </Card>
    </Stack>
  )
}
