import { JournalEntryModel, PersonalJournalModel } from "../SharedModel";
import {
  JournalEntryModelSample,
  PersonalJournalModelSample,
} from "../ModelSample";
import { Util } from "../Util";
import { AxiosHost } from "./ApiConsts";

interface GetEntryResponse {
  journal?: PersonalJournalModel;
  entry?: JournalEntryModel;
}

interface CreateTemplateResponse {
  journal: PersonalJournalModel;
}

interface CreateEntryResponse {
  journal: PersonalJournalModel;
  entry: JournalEntryModel;
}

const GetEntryResponseSample: GetEntryResponse = {};
const CreateTemplateResponseSample: CreateTemplateResponse = {
  journal: PersonalJournalModelSample,
};
const CreateEntryResponseSample: CreateEntryResponse = {
  journal: PersonalJournalModelSample,
  entry: JournalEntryModelSample,
};

type GetEntryResponseType =
  | { success: true; response: GetEntryResponse }
  | { success: false; error: string };

type CreateTemplateResponseType =
  | { success: true; response: CreateTemplateResponse }
  | { success: false; error: string };

type CreateEntryResponseType =
  | { success: true; response: CreateEntryResponse }
  | { success: false; error: string };

export const JournalApi = {
  getEntry: async (dateISO: string): Promise<GetEntryResponseType> => {
    let response = await AxiosHost.Core({
      url: `journal/getjournal`,
      method: "GET",
      params: { dateISO },
    });

    if (Util.is<GetEntryResponse>(response.data, GetEntryResponseSample)) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  updateJournal: async (
    templates: string[]
  ): Promise<CreateTemplateResponseType> => {
    const formData = new FormData();
    for (var i = 0; i < templates.length; i++) {
      formData.append("templates[]", templates[i]);
    }

    let response = await AxiosHost.Core({
      url: `journal/updatejournal`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (
      Util.is<CreateTemplateResponse>(
        response.data,
        CreateTemplateResponseSample
      )
    ) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  createUpdateEntry: async (
    dateISO: string,
    prompts: string[],
    responses: string[]
  ): Promise<CreateEntryResponseType> => {
    const formData = new FormData();
    formData.append("dateISO", dateISO);
    for (var i = 0; i < prompts.length; i++) {
      formData.append("prompts[]", prompts[i]);
    }
    for (var i = 0; i < responses.length; i++) {
      formData.append("responses[]", responses[i]);
    }

    let response = await AxiosHost.Core({
      url: `journal/entry`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (
      Util.is<CreateEntryResponse>(response.data, CreateEntryResponseSample)
    ) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
};
