import React, { useState } from "react"
import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  Modal,
  ModalDialog,
  Stack,
} from "@mui/joy"
import { DateUtils } from "../../common/DateUtils"
import { useNavigate } from "react-router-dom"
import { CalendarToday } from "@mui/icons-material"
import { MIN_DATE } from "../../common/Consts"
import { useAppSelector } from "../../common/state/hooks"
import { CampfireSelector } from "../../common/state/store"
import { DaysViewMode, ViewMode, createURL } from "../common/Navigate"

interface Props {
  currentDate: Date
  viewMode: ViewMode
  daysViewMode: DaysViewMode
}
export const DatePanel = ({ currentDate, viewMode, daysViewMode }: Props) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(DateUtils.DateISO(currentDate))

  const openPanel = () => {
    setDate(DateUtils.DateISO(currentDate))
    setOpen(true)
  }

  const seeQuestion = () => {
    setOpen(false)
    navigate(createURL({ dateISO: date, viewMode, daysViewMode }))
  }

  const seeQuestionToday = () => {
    setOpen(false)
    navigate(
      createURL({
        dateISO: DateUtils.DateISO(new Date()),
        viewMode,
        daysViewMode,
      }),
    )
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newDateString = event.target.value
    if (!newDateString) {
      newDateString = DateUtils.DateISO(new Date())
    } else {
      let newDate = DateUtils.ParseDate(newDateString)
      if (DateUtils.Before(newDate, DateUtils.ParseDate(MIN_DATE))) {
        //IF BEFORE MIN DATE
        newDateString = MIN_DATE
      } else if (DateUtils.Before(new Date(), newDate)) {
        newDateString = DateUtils.DateISO(new Date())
      }
    }

    setDate(newDateString)
  }

  return (
    <React.Fragment>
      <IconButton onClick={openPanel} size={"md"}>
        <CalendarToday />
      </IconButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Date</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Input
                type="date"
                value={date}
                slotProps={{
                  input: {
                    min: MIN_DATE,
                    max: DateUtils.DateISO(new Date()),
                  },
                }}
                onChange={handleChange}
              />
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Button onClick={seeQuestionToday}>See Today</Button>
                <Button onClick={seeQuestion}>See Date</Button>
              </Stack>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Stack>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  )
}
