export class ObjUtil {
  static keys<T>(arg: { [key: string]: T | undefined }): string[] {
    let r = [];
    for (let x of Object.keys(arg)) {
      if (x !== undefined) {
        r.push(x);
      }
    }
    return r;
  }
  static values<T>(arg: { [key: string]: T | undefined }): T[] {
    let r = [];
    for (let x of Object.values(arg)) {
      if (x !== undefined) {
        r.push(x);
      }
    }
    return r;
  }
}
