import { Stack, Alert, Textarea, Button, Checkbox, Typography } from "@mui/joy"
import { useState } from "react"
import { CampfireActions } from "../../../common/state/CampfireRedux"
import { useAppDispatch } from "../../../common/state/hooks"
import { DateUtils } from "../../../common/DateUtils"
import { ResponseShareType } from "../../../common/SharedEnum"
import { DailyQuestionReduxModel } from "../../../common/ModelExtended"
import { ApiUtil } from "../../../common_not/ApiUtil"
import { ResponseApi } from "../../../common/api/ResponseApi"
import { useNavigate } from "react-router-dom"
import { DaysViewMode, ViewMode, createURL } from "../../common/Navigate"

interface ITypeResponse {
  question: DailyQuestionReduxModel
  isToday: boolean
  dateISO: string
}
export const ResponseInputView = ({
  isToday,
  question,
  dateISO,
}: ITypeResponse) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [resText, setResText] = useState("")
  const [error, setError] = useState("")
  const [shareType, setShareType] = useState(ResponseShareType.Public)

  const submitResponse = (journalNext: boolean) => async () => {
    setLoading(true)
    setError("")
    const res = await ApiUtil.ApiWrap(
      ResponseApi.submitResponse(
        dateISO,
        question.id,
        resText,
        shareType,
        DateUtils.ToISO(new Date()),
      ),
    )
    if (res.success) {
      dispatch(
        CampfireActions.update({
          questions: [{ ...question, response: res.response }],
        }),
      )
    } else {
      setError(res.error)
    }
    setLoading(false)

    if (journalNext) {
      navigate(
        createURL({
          dateISO,
          viewMode: ViewMode.PersonalJournal,
          daysViewMode: DaysViewMode.Day,
        }),
      )
    }
  }

  const onShareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setShareType(ResponseShareType.Public)
    } else {
      setShareType(ResponseShareType.Personal)
    }
  }

  const canSave = !loading && !resText

  return (
    <Stack spacing={2}>
      {question.totalCount == 0 && (
        <Alert color="success">You're the first to answer this question!</Alert>
      )}
      <Textarea
        placeholder={`Put your thoughts here! \n\nYou can uncheck "Share with others" if you'd prefer to keep it private.\n\nYou cannot edit your answer once you save it.`}
        minRows={6}
        onChange={(val) => setResText(val.target.value)}
        value={resText}
        variant="outlined"
        disabled={loading}
      />
      <Checkbox
        checked={shareType === ResponseShareType.Public}
        onChange={onShareChange}
        label="Share with others"
        disabled={loading}
      />
      <Stack direction={"row"} alignItems={"center"} spacing={3}>
        <Button
          disabled={canSave}
          loading={loading}
          onClick={submitResponse(true)}
        >
          Save and Journal
        </Button>
        <Typography>or</Typography>
        <Button
          disabled={canSave}
          loading={loading}
          onClick={submitResponse(false)}
        >
          Save
        </Button>
      </Stack>
      {error && <Alert color="danger">{error}</Alert>}
      {question.totalCount > 1 && (
        <Typography>{`${question.totalCount} people have answered this question.`}</Typography>
      )}
      {question.totalCount == 1 && (
        <Typography>{`Someone has answered this question.`}</Typography>
      )}
    </Stack>
  )
}
