import { ItemModelSample, ListFrontendModelSample } from "../ModelSample";
import { CompleteType, ListType } from "../SharedEnum";
import { ItemModel, ListFrontendModel } from "../SharedModel";
import { Util } from "../Util";
import { AxiosHost } from "./ApiConsts";

interface GetListsApiResponse {
  lists: ListFrontendModel[];
}

interface GetItemsApiResponse {
  items: ItemModel[];
}

const GetListsApiResponseSample: GetListsApiResponse = {
  lists: [ListFrontendModelSample],
};

const GetItemsApiResponseSample: GetItemsApiResponse = {
  items: [ItemModelSample],
};

type CreateListApiResponseType =
  | { success: true; response: ListFrontendModel }
  | { success: false; error: string };

type CreateItemApiResponseType =
  | { success: true; response: ItemModel }
  | { success: false; error: string };

type GetListsApiResponseType =
  | { success: true; response: GetListsApiResponse }
  | { success: false; error: string };

type GetItemsApiResponseType =
  | { success: true; response: GetItemsApiResponse }
  | { success: false; error: string };

export const ListApi = {
  createList: async (
    listName: string,
    listType: ListType,
    listCompleteType: CompleteType
  ): Promise<CreateListApiResponseType> => {
    const formData = new FormData();
    formData.append("listName", listName);
    formData.append("listType", listType.toString());
    formData.append("listCompleteType", listCompleteType.toString());

    var response = await AxiosHost.Core({
      url: `list/create`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (Util.is<ListFrontendModel>(response.data, ListFrontendModelSample)) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  getLists: async (): Promise<GetListsApiResponseType> => {
    let response = await AxiosHost.Core({
      url: `list/lists`,
      method: "GET",
    });
    if (
      Util.is<GetListsApiResponse>(response.data, GetListsApiResponseSample)
    ) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  deleteList: async (listId: string): Promise<{ success: true }> => {
    await AxiosHost.Core({
      url: `list/delete`,
      method: "DELETE",
      params: { listId },
    });

    return { success: true };
  },
  createItem: async (
    listId: string,
    itemText: string
  ): Promise<CreateItemApiResponseType> => {
    const formData = new FormData();
    formData.append("listId", listId);
    formData.append("itemText", itemText.toString());

    var response = await AxiosHost.Core({
      url: `list/item`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (Util.is<ItemModel>(response.data, ItemModelSample)) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  getItems: async (listId: string): Promise<GetItemsApiResponseType> => {
    let response = await AxiosHost.Core({
      url: `list/item`,
      method: "GET",
      params: { listId },
    });
    if (
      Util.is<GetItemsApiResponse>(response.data, GetItemsApiResponseSample)
    ) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  deleteItem: async (
    listId: string,
    itemId: string
  ): Promise<{ success: true }> => {
    await AxiosHost.Core({
      url: `list/item`,
      method: "DELETE",
      params: { listId, itemId },
    });

    return { success: true };
  },
};
