import { UserModelSample, UserReferralModelSample } from "../ModelSample";
import { UserModel, UserReferralModel } from "../SharedModel";
import { Util } from "../Util";
import { AxiosHost } from "./ApiConsts";

export interface UserApiResponse {
  user: UserModel;
  token: string;
}
const UserApiResponseSample: UserApiResponse = {
  user: UserModelSample,
  token: "string",
};
export type UserApiResponseType =
  | { success: true; response: UserApiResponse }
  | { success: false; error: string };

interface UserReferralApiResponse {
  referral: UserReferralModel;
}
const UserReferralApiResponseSample: UserReferralApiResponse = {
  referral: UserReferralModelSample,
};
export type UserReferralApiResponseType =
  | { success: true; response: UserReferralApiResponse }
  | { success: false; error: string };

export const UserApi = {
  update: async (
    displayName: string,
    email: string
  ): Promise<UserApiResponseType> => {
    const formData = new FormData();
    formData.append("displayName", displayName);
    formData.append("email", email);

    let response = await AxiosHost.Core({
      url: `user/update`,
      method: "PUT",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (Util.is<UserApiResponse>(response.data, UserApiResponseSample)) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  updatePassword: async (
    loginCode: string,
    password: string
  ): Promise<UserApiResponseType> => {
    const formData = new FormData();
    formData.append("loginCode", loginCode);
    formData.append("password", password);

    let response = await AxiosHost.Core({
      url: `user/updatepassword`,
      method: "PUT",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (Util.is<UserApiResponse>(response.data, UserApiResponseSample)) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  create: async (
    displayName: string,
    password: string,
    email: string,
    authCode: string
  ): Promise<UserApiResponseType> => {
    const formData = new FormData();
    formData.append("displayName", displayName);
    formData.append("password", password);
    formData.append("email", email);
    formData.append("authCode", authCode);

    let response = await AxiosHost.Core({
      url: `user/create`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (Util.is<UserApiResponse>(response.data, UserApiResponseSample)) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  login: async (
    displayName: string,
    passwordOrLoginCode: string
  ): Promise<UserApiResponseType> => {
    const formData = new FormData();
    formData.append("displayName", displayName);
    formData.append("passwordOrLoginCode", passwordOrLoginCode);

    let response = await AxiosHost.Core({
      url: `user/login`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (Util.is<UserApiResponse>(response.data, UserApiResponseSample)) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  refresh: async (token: string): Promise<UserApiResponseType> => {
    let response = await AxiosHost.Core({
      url: `user/refresh`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (Util.is<UserApiResponse>(response.data, UserApiResponseSample)) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  createReferral: async (): Promise<UserReferralApiResponseType> => {
    let response = await AxiosHost.Core({
      url: `user/referral`,
      method: "GET",
    });

    if (
      Util.is<UserReferralApiResponse>(
        response.data,
        UserReferralApiResponseSample
      )
    ) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
};
