import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DailyQuestionModel,
  ReportModel,
  ResponseDBModel,
  UserModel,
} from "../SharedModel";
import { ReportState } from "./ReportModel";

const initialState: ReportState = {
  users: {},
  questions: {},
  responses: {},
  reports: {},
};

interface UpdatePayload {
  users?: UserModel[];
  questions?: DailyQuestionModel[];
  responses?: ResponseDBModel[];
  reports?: ReportModel[];
}

export const ReportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<UpdatePayload>) => {
      const { users, questions, responses, reports } = action.payload;
      if (users) {
        for (let x of users) {
          state.users[x.id] = x;
        }
      }
      if (responses) {
        for (let x of responses) {
          state.responses[x.id] = x;
        }
      }
      if (questions) {
        for (let x of questions) {
          state.questions[x.id] = x;
        }
      }
      if (reports) {
        for (let x of reports) {
          state.reports[x.id] = x;
        }
      }
    },
    delete: (state, action: PayloadAction<UpdatePayload>) => {
      const { reports } = action.payload;
      if (reports) {
        for (let x of reports) {
          delete state.reports[x.id];
        }
      }
    },
  },
});

export const ReportActions = ReportSlice.actions;
export const ReportReducer = ReportSlice.reducer;
