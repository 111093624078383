import {
  Button,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  DialogActions,
  Alert,
} from "@mui/joy"
import React, { useState } from "react"
import { ApiUtil } from "../../common_not/ApiUtil"
import { UserApi } from "../../common/api/UserApi"
import { UserModel, UserReferralModel } from "../../common/SharedModel"

interface Props {
  user: UserModel
  onOpen: () => void
  onClose: () => void
}
export const ReferralPanel = ({ user, onClose }: Props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [referral, setReferral] = useState<UserReferralModel>()

  const onClickGenerate = async () => {
    setLoading(true)
    setError("")
    const response = await ApiUtil.ApiWrap(UserApi.createReferral())
    if (response.success === true) {
      setReferral(response.response.referral)
    } else {
      setError(response.error)
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      <DialogTitle>Invite Friends</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Typography level="body-md">
            Invite your friends to use Duvalaki! <br />
            Each invite allows up to 5 people to register.
          </Typography>
          {referral && (
            <React.Fragment>
              <Stack spacing={1}>
                <Typography level="title-md">Invite Code</Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={3}>
                  <Typography level="body-md">
                    {referral.referralCode}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigator.clipboard.writeText(referral.referralCode)
                    }}
                    disabled={loading}
                  >
                    Copy
                  </Button>
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <Typography level="title-md">Invite Link</Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={3}>
                  <Typography level="body-md">{`https://duvalaki.app/#invite=${referral.referralCode}`}</Typography>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://duvalaki.app/#invite=${referral.referralCode}`,
                      )
                    }}
                    disabled={loading}
                  >
                    Copy
                  </Button>
                </Stack>
              </Stack>
            </React.Fragment>
          )}
          {error && <Alert color="danger">{error}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={loading}>
          Close
        </Button>
        <Button
          onClick={onClickGenerate}
          loading={loading}
          disabled={referral !== undefined}
        >
          Generate Invite
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}
