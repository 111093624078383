import { useEffect } from "react"
import { journalThunk } from "../../common/state/JournalThunk"
import { useAppDispatch, useAppSelector } from "../../common/state/hooks"
import { CampfireSelector, JournalSelector } from "../../common/state/store"
import { DateUtils } from "../../common/DateUtils"
import { Stack, CircularProgress, Alert } from "@mui/joy"
import { CreateJournalView, JournalCreateView } from "./JournalCreateView"
import { JournalEntryView } from "./JournalEntryView"

interface Props {
  date: Date
}
export const JournalView = ({ date }: Props) => {
  const dateISO = DateUtils.DateISO(date)
  const dispatch = useAppDispatch()
  const personalJournal = useAppSelector(JournalSelector.personalJournal)
  const apiState = useAppSelector(JournalSelector.apiState(dateISO))

  useEffect(() => {
    if (apiState === undefined) {
      dispatch(journalThunk({ dateISO, replace: false }))
    }
  }, [apiState])

  if (apiState === undefined) {
    return <div />
  }

  const { loading, error } = apiState
  let body
  if (loading) {
    body = (
      <Stack alignItems={"center"} padding={5}>
        <CircularProgress />
      </Stack>
    )
  } else if (error) {
    body = <Alert color="danger">{error}</Alert>
  } else if (personalJournal) {
    body = <JournalEntryView journal={personalJournal} dateISO={dateISO} />
  } else {
    body = (
      <CreateJournalView
        initTemplates={[]}
        initView={JournalCreateView.Intro}
      />
    )
  }

  return (
    <Stack paddingTop={1} paddingBottom={5}>
      {body}
    </Stack>
  )
}
