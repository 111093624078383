import {
  Button,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  DialogActions,
} from "@mui/joy"
import React from "react"
import { UserModel } from "../../common/SharedModel"

interface Props {
  user: UserModel
  onOpen: () => void
  onClose: () => void
}
export const SupportPanel = ({ user, onClose }: Props) => {
  const sendEmail = (subject: string) => {
    var email = user.supportEmail
    var subject = `${subject}: ${user.displayName}`
    document.location = "mailto:" + email + "?subject=" + subject
  }

  return (
    <React.Fragment>
      <DialogTitle>Support</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Stack spacing={1} alignItems={"flex-start"}>
            <Typography>
              Do you have an amazing question? Let me know!
            </Typography>
            <Button onClick={() => sendEmail("Question Suggestions")}>
              Question Suggestions
            </Button>
          </Stack>
          <Stack spacing={1} alignItems={"flex-start"}>
            <Typography>
              Do you have any feedback and suggestions? I would love to hear
              from you!
            </Typography>
            <Button onClick={() => sendEmail("Feedback and Suggestions")}>
              Feedback and Suggestions
            </Button>
          </Stack>
          <Stack spacing={1} alignItems={"flex-start"}>
            <Typography>
              Did you encounter a bug? Please include as much information as
              possible.
            </Typography>
            <Button onClick={() => sendEmail("Bug Report")}>Bug Report</Button>
          </Stack>
          <Stack spacing={1} alignItems={"flex-start"}>
            <Typography>Do you need help? Please reach out!</Typography>
            <Button onClick={() => sendEmail("Support Request")}>
              Support Request
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <div />
      </DialogActions>
    </React.Fragment>
  )
}
