import { useState } from "react"
import { JournalIntroView } from "./JournalIntroView"
import { JournalTemplateView } from "./JournalTemplateView"
import { JournalTemplateEditView } from "./JournalTemplateEditView"
import { produce } from "immer"
import { JournalTemplateModel } from "../../common/SharedModel"
import { DefaultJournalTemplates } from "./JournalTemplates"

export enum JournalCreateView {
  Intro = 1,
  Templates = 2,
  // Edit = 3,
}

interface Props {
  initView: JournalCreateView
  initTemplates: JournalTemplateModel[]
  closeEditView?: () => void
}

export const CreateJournalView = ({
  initView,
  initTemplates,
  closeEditView,
}: Props) => {
  const [view, setView] = useState(initView)
  const [templates, setTemplates] = useState(initTemplates)
  const [editIndex, setEditIndex] = useState<number>(-1)

  const onSelectTemplate = (index: number) => {
    setEditIndex(index)
  }

  const onClickTemplates = () => {
    setTemplates(DefaultJournalTemplates)
    setView(JournalCreateView.Templates)
  }

  const onSaveTemplate = (template: JournalTemplateModel) => {
    const newTemplates = produce(templates, (draft) => {
      draft[editIndex] = template
    })
    setTemplates(newTemplates)
    setEditIndex(-1)
  }

  const onDeleteTemplate = (template: JournalTemplateModel) => {
    const index = templates.indexOf(template)
    if (index > -1) {
      const newTemplates = produce(templates, (draft) => {
        draft.splice(index, 1)
      })
      setTemplates(newTemplates)
      setEditIndex(-1)
    }
  }
  const onClickAddTemplate = () => {
    const newTemplates = produce(templates, (draft) => {
      draft.push({
        name: "",
        description: "",
        prompts: [],
      })
    })
    setTemplates(newTemplates)
    setEditIndex(newTemplates.length - 1)
  }

  let body = <div />
  switch (view) {
    case JournalCreateView.Intro:
      return <JournalIntroView onClickTemplates={onClickTemplates} />
      break
    case JournalCreateView.Templates:
      if (editIndex >= 0) {
        body = (
          <JournalTemplateEditView
            initTemplate={templates[editIndex]}
            saveTemplate={onSaveTemplate}
            onDeleteTemplate={onDeleteTemplate}
          />
        )
      } else {
        body = (
          <JournalTemplateView
            templates={templates}
            onClickAddTemplate={onClickAddTemplate}
            onClickTemplate={onSelectTemplate}
            closeEditView={closeEditView}
          />
        )
      }
  }

  return body
}
