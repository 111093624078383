import { DailyQuestionModelSample, DayInfoModelSample } from "../ModelSample";
import {
  DailyQuestionModel,
  DayInfoModel,
  ResponseDBModel,
} from "../SharedModel";
import { Util } from "../Util";
import { AxiosHost } from "./ApiConsts";

export interface GetQuestionApiResponse {
  dayInfo: DayInfoModel;
  question: DailyQuestionModel;
  response?: ResponseDBModel;
  totalCount: number;
}

const GetQuestionApiResponseSample: GetQuestionApiResponse = {
  dayInfo: DayInfoModelSample,
  question: DailyQuestionModelSample,
  totalCount: 1,
};

type GetQuestionApiResponseType =
  | { success: true; response?: GetQuestionApiResponse }
  | { success: false; error: string };

type SubmitResponseApiResponseType =
  | { success: true }
  | { success: false; error: string };

export const QuestionApi = {
  getQuestion: async (date: string): Promise<GetQuestionApiResponseType> => {
    let response = await AxiosHost.Core({
      url: `dailyquestion`,
      method: "GET",
      params: { date },
    });

    if (response.status === 204) {
      return { success: true };
    } else if (
      Util.is<GetQuestionApiResponse>(
        response.data,
        GetQuestionApiResponseSample
      )
    ) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  createQuestion: async (
    passcode: string,
    date: string,
    daysAhead: number,
    questionText: string,
    questionExtension: string,
    newQuestion: boolean,
    questionId?: string
  ): Promise<SubmitResponseApiResponseType> => {
    const formData = new FormData();
    formData.append("passcode", passcode);
    formData.append("date", date);
    formData.append("daysAhead", daysAhead.toString());
    formData.append("questionText", questionText);
    formData.append("questionExtension", questionExtension);
    formData.append("newQuestion", newQuestion.toString());
    questionId && formData.append("questionId", questionId);

    await AxiosHost.Core({
      url: `dailyquestion/create`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return { success: true };
  },
  deleteQuestion: async (
    passcode: string,
    date: string,
    confirm: boolean
  ): Promise<{ success: true }> => {
    const formData = new FormData();
    formData.append("passcode", passcode);
    formData.append("date", date);
    formData.append("confirm", confirm ? "true" : "false");

    await AxiosHost.Core({
      url: `dailyquestion/delete`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return { success: true };
  },
  getQuestionDebug: async (
    passcode: string,
    date: string
  ): Promise<GetQuestionApiResponseType> => {
    const formData = new FormData();
    formData.append("passcode", passcode);
    formData.append("date", date);

    let response = await AxiosHost.Core({
      url: `dailyquestion/getdebug`,
      method: "PUT",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (
      Util.is<GetQuestionApiResponse>(
        response.data,
        GetQuestionApiResponseSample
      )
    ) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
};
