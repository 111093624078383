import {
  UserModelSample,
  DailyQuestionModelSample,
  ResponseDBModelSample,
  ReportModelSample,
} from "../ModelSample";
import {
  DailyQuestionModel,
  ReportModel,
  ResponseDBModel,
  UserModel,
} from "../SharedModel";
import { Util } from "../Util";
import { AxiosHost } from "./ApiConsts";

export interface GetReportApiResponse {
  users: UserModel[];
  questions: DailyQuestionModel[];
  responses: ResponseDBModel[];
  reports: ReportModel[];
}

const GetReportApiResponseSample: GetReportApiResponse = {
  users: [UserModelSample],
  questions: [DailyQuestionModelSample],
  responses: [ResponseDBModelSample],
  reports: [ReportModelSample],
};

export type GetReportApiResponseResponseType =
  | { success: true; response: GetReportApiResponse }
  | { success: false; error: string };

export type ReportApiResponseType =
  | { success: true }
  | { success: false; error: string };

export const ReportApi = {
  submitReport: async (
    questionId: string,
    responseId: string,
    reason: string
  ): Promise<ReportApiResponseType> => {
    const formData = new FormData();
    formData.append("questionId", questionId);
    formData.append("responseId", responseId);
    formData.append("reason", reason);

    await AxiosHost.Core({
      url: `report/reportresponse`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return { success: true };
  },
  getAllReports: async (
    passcode: string
  ): Promise<GetReportApiResponseResponseType> => {
    const formData = new FormData();
    formData.append("passcode", passcode);

    let response = await AxiosHost.Core({
      url: `report/getallreport`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (
      Util.is<GetReportApiResponse>(response.data, GetReportApiResponseSample)
    ) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  deleteReport: async (
    passcode: string,
    deleteQuestion: boolean,
    reportId: string
  ): Promise<ReportApiResponseType> => {
    const formData = new FormData();
    formData.append("passcode", passcode);
    formData.append("deleteQuestion", deleteQuestion ? "true" : "false");
    formData.append("reportId", reportId);

    await AxiosHost.Core({
      url: `report/deletereport`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return { success: true };
  },
};
