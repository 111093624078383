import React, { useEffect, useState } from "react"
import {
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalDialog,
} from "@mui/joy"
import { UserModel } from "../../common/SharedModel"
import { MoreHoriz } from "@mui/icons-material"
import { ProfilePanel } from "./ProfilePanel"
import { SupportPanel } from "./SupportPanel"
import { LogoutPanel } from "./LogoutPanel"
import { AboutPanel } from "./AboutPanel"
import { AppName } from "../../common/Consts"
import { SetPasswordPanel } from "./SetPasswordPanel"
import { ReferralPanel } from "./ReferPanel"

export enum MenuType {
  Support = 1,
  Profile = 2,
  Logout = 3,
  About = 4,
  SetPass = 5,
  Referral = 6,
}
export const DropdownMenu = ({ user }: { user: UserModel }) => {
  const [menuType, setType] = useState(MenuType.Support)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!user.email) {
      setType(MenuType.Profile)
      setOpen(true)
    } else if (!user.passwordSet) {
      setType(MenuType.SetPass)
      setOpen(true)
    }
  }, [user])

  let body = (
    <SupportPanel
      user={user}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  )
  switch (menuType) {
    case MenuType.Support:
      body = (
        <SupportPanel
          user={user}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        />
      )
      break
    case MenuType.Logout:
      body = (
        <LogoutPanel
          user={user}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        />
      )
      break
    case MenuType.About:
      body = (
        <AboutPanel
          user={user}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        />
      )
      break
    case MenuType.SetPass:
      body = (
        <SetPasswordPanel
          user={user}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        />
      )
      break
    case MenuType.Referral:
      body = (
        <ReferralPanel
          user={user}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        />
      )
      break
    default:
      body = (
        <ProfilePanel
          user={user}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        />
      )
  }

  const onClick = (type: MenuType) => () => {
    setType(type)
    setOpen(true)
  }

  return (
    <React.Fragment>
      <Dropdown>
        <MenuButton slots={{ root: IconButton }}>
          <MoreHoriz />
        </MenuButton>
        <Menu>
          <MenuItem onClick={onClick(MenuType.Referral)}>
            Invite Friends
          </MenuItem>
          <MenuItem onClick={onClick(MenuType.Profile)}>Edit Profile</MenuItem>
          <MenuItem onClick={onClick(MenuType.About)}>About {AppName}</MenuItem>
          <MenuItem onClick={onClick(MenuType.Support)}>
            Contact Support
          </MenuItem>
          <MenuItem onClick={onClick(MenuType.Logout)}>Logout</MenuItem>
        </Menu>
      </Dropdown>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>{body}</ModalDialog>
      </Modal>
    </React.Fragment>
  )
}
