import { JournalTemplateModel } from "../../common/SharedModel"

export const DefaultJournalTemplates: JournalTemplateModel[] = [
  {
    name: "Blank Space",
    description:
      "No prompts. Your journal is a blank space for you to express whatever you're feeling, however you're feeling that day.",
    prompts: [],
  },
  {
    name: "Gratitude",
    description: `Practice regular gratitude. These prompts focus on shiting your mindset to focus on the great things in life we can take for granted. 
      The big things, the little things, and everything in between, life is rarely all bad.`,
    prompts: [
      "What's one thing you're thankful for today?",
      "What's one thing that made you smile today?",
      "What's one thing you're excited about?",
    ],
  },
  {
    name: "Reflection",
    description:
      "Reflect regularly. Life has a tendency to pass by quicker than we expect, reflect on what happened and how you feel.",
    prompts: [
      "What happened? Focus on activity, the place, and the people there.",
      "How do you feel? Focus on you, and what you felt",
    ],
  },
]
