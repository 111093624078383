import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUtil } from "../../common_not/ApiUtil";
import { JournalActions } from "./JournalRedux";
import { JournalApi } from "../api/JournalApi";

interface Payload {
  dateISO: string;
  replace: boolean;
}
export const journalThunk = createAsyncThunk(
  "journal/journalThunk",
  async (payload: Payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { dateISO, replace } = payload;

    if (replace) {
      dispatch(JournalActions.clearJournal({ dateISO: dateISO }));
    }

    dispatch(
      JournalActions.update({
        apiState: [
          {
            dateISO: dateISO,
            loading: true,
            sucess: false,
            error: "",
          },
        ],
      })
    );
    const res = await ApiUtil.ApiWrap(JournalApi.getEntry(dateISO));
    if (res.success) {
      dispatch(
        JournalActions.update({
          personalJournal: res.response.journal,
          entries: res.response.entry ? [res.response.entry] : undefined,
          apiState: [
            {
              dateISO: dateISO,
              loading: false,
              sucess: true,
              error: "",
            },
          ],
        })
      );
    } else {
      dispatch(
        JournalActions.update({
          apiState: [
            {
              dateISO: dateISO,
              loading: false,
              sucess: false,
              error: res.error,
            },
          ],
        })
      );
    }
  }
);
