import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../common/state/hooks"
import { CampfireSelector, CoreSelector } from "../../../common/state/store"
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/joy"
import { DateUtils } from "../../../common/DateUtils"
import { ObjUtil } from "../../../common/ObjUtil"
import {
  ResponseDisplayModel,
  ResponseDBModel,
  UserModel,
} from "../../../common/SharedModel"
import { DailyQuestionReduxModel } from "../../../common/ModelExtended"
import { Paragraphs } from "../../common/Paragraphs"
import { responseThunk } from "../../../common/state/CampfireThunk"
import { ResponseDropdown } from "./ResponseDropdown"
import { ResponseInputView } from "./ResponseInputView"

interface IResponseView {
  date: Date
  isToday: boolean
}
export const ResponseView = ({ date, isToday }: IResponseView) => {
  const user = useAppSelector(CoreSelector.user)
  const dayInfos = useAppSelector(CampfireSelector.dayInfos)
  const questions = useAppSelector(CampfireSelector.questions)
  const dateISO = DateUtils.DateISO(date)
  const dayInfo = dayInfos[dateISO]
  const todayQuestion = questions[dayInfo?.questionId || ""]

  if (!user || !todayQuestion) {
    return <div />
  }

  let yourResponse = todayQuestion.response

  if (yourResponse) {
    return (
      <ShowResponse
        dateISO={dateISO}
        user={user}
        question={todayQuestion}
        userResponse={yourResponse}
      />
    )
  } else {
    return (
      <ResponseInputView
        question={todayQuestion}
        isToday={isToday}
        dateISO={dateISO}
      />
    )
  }
}

interface IShowResponse {
  dateISO: string
  user: UserModel
  question: DailyQuestionReduxModel
  userResponse: ResponseDBModel
}
const ShowResponse = ({
  dateISO,
  user,
  question,
  userResponse,
}: IShowResponse) => {
  const dispatch = useAppDispatch()
  const responses = useAppSelector(CampfireSelector.responses)
  const apiState = useAppSelector(CampfireSelector.responseApiState(dateISO))
  const yourResponse: ResponseDisplayModel = {
    ...userResponse,
    userDisplayName: user.displayName,
  }

  useEffect(() => {
    if (apiState === undefined) {
      dispatch(responseThunk({ dateISO, questionId: question.id }))
    }
  }, [apiState, question])

  let body
  if (apiState === undefined) {
    return <div />
  } else {
    const { loading, error } = apiState
    if (loading) {
      body = (
        <Stack alignItems={"center"} padding={5}>
          <CircularProgress />
        </Stack>
      )
    } else if (error) {
      body = <Alert color="danger">{error}</Alert>
    } else {
      body = []
      body.push(
        <SingleResponse
          key={question.id + yourResponse.id}
          userResponse={true}
          question={question}
          response={yourResponse}
        />,
      )
      for (let response of ObjUtil.values(responses)) {
        if (response.questionId === question.id) {
          body.push(
            <SingleResponse
              key={question.id + response.id}
              question={question}
              response={response}
            />,
          )
        }
      }
    }
  }

  return <Stack spacing={3}>{body}</Stack>
}

interface ISingleResponse {
  userResponse?: boolean
  question: DailyQuestionReduxModel
  response: ResponseDisplayModel
}
const SingleResponse = ({
  userResponse,
  question,
  response,
}: ISingleResponse) => {
  let timePosted = DateUtils.ParseDate(response.timePosted)

  return (
    <Stack spacing={0.5}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingLeft={2}
      >
        <Typography level="title-md">
          {`${response.userDisplayName}, ${DateUtils.DisplayTime(timePosted)}`}
        </Typography>
        <ResponseDropdown
          userResponse={userResponse}
          question={question}
          response={response}
        />
      </Stack>
      <Card variant="soft">
        <CardContent>
          <Paragraphs text={response.questionAnswer} />
        </CardContent>
      </Card>
    </Stack>
  )
}
