import {
  Alert,
  Button,
  Card,
  CardContent,
  Link,
  Stack,
  Typography,
} from "@mui/joy"
import { Paragraphs } from "../common/Paragraphs"
import React, { useState } from "react"
import { JournalTemplateModel } from "../../common/SharedModel"
import { JournalActions } from "../../common/state/JournalRedux"
import { ApiUtil } from "../../common_not/ApiUtil"
import { JournalApi } from "../../common/api/JournalApi"
import { useAppDispatch } from "../../common/state/hooks"

interface Props {
  templates: JournalTemplateModel[]
  onClickAddTemplate: () => void
  onClickTemplate: (index: number) => void
  closeEditView?: () => void
}

export const JournalTemplateView = ({
  templates,
  onClickAddTemplate,
  onClickTemplate,
  closeEditView,
}: Props) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const onSaveJournal = async () => {
    setError("")
    setLoading(true)
    const res = await ApiUtil.ApiWrap(
      JournalApi.updateJournal(templates.map((x) => JSON.stringify(x))),
    )
    if (res.success) {
      dispatch(
        JournalActions.update({
          personalJournal: res.response.journal,
        }),
      )
    } else {
      setError(res.error)
    }
    setLoading(false)
    closeEditView && closeEditView()
  }

  const cantSave = templates.length < 1 || loading

  return (
    <Stack spacing={2}>
      <Typography level="h3">Your Journals</Typography>
      <Stack direction={"row"} justifyContent={"space-between"}>
        {closeEditView && (
          <Button onClick={closeEditView} disabled={loading} variant="outlined">
            Cancel
          </Button>
        )}
        <Button onClick={onSaveJournal} disabled={cantSave} loading={loading}>
          Save Journals
        </Button>
      </Stack>
      {error && <Alert color="danger">{error}</Alert>}
      <Button onClick={onClickAddTemplate} loading={loading} variant="outlined">
        Create New Journal
      </Button>
      {templates.map((x, index) => (
        <Card key={x.name + index} variant="soft">
          <CardContent>
            <Stack spacing={2}>
              <Stack>
                <Typography level="title-md">
                  <Link
                    overlay
                    underline="none"
                    // href="#interactive-card"
                    // sx={{ color: "text.tertiary" }}
                    onClick={() => onClickTemplate(index)}
                  >
                    {x.name}
                  </Link>
                </Typography>
                {x.description && <Paragraphs text={x.description} />}
              </Stack>
              <Stack>
                <Typography level="title-md">Questions</Typography>
                {x.prompts.length < 1 ? (
                  <Typography>None</Typography>
                ) : (
                  <ul>
                    {x.prompts.map((p) => (
                      <li key={p}>
                        <Typography>{p}</Typography>
                      </li>
                    ))}
                  </ul>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>
  )
}
