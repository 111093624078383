import { Card, CardContent, Typography, Stack, Button } from "@mui/joy"
import { JournalEntryModel } from "../../../common/SharedModel"

interface ShowEntryViewProps {
  entry: JournalEntryModel
  setEditEntry: React.Dispatch<React.SetStateAction<boolean>>
}
export const ShowEntryView = ({ entry, setEditEntry }: ShowEntryViewProps) => {
  let views = []
  for (let i = 0; i < entry.responses.length; i++) {
    views.push(
      <Card key={i + "entry"} variant="soft">
        <CardContent>
          <Typography level={"title-md"}>{entry.prompts[i]}</Typography>
          <Typography level={"body-md"}>{entry.responses[i]}</Typography>
        </CardContent>
      </Card>,
    )
  }
  return (
    <Stack spacing={2}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography level="h3">Journal Entry</Typography>
        <Button variant="outlined" onClick={() => setEditEntry(true)}>
          Edit Entry
        </Button>
      </Stack>
      {views}
    </Stack>
  )
}
