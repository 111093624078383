import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DateUtils } from "../DateUtils";
import { UserModel } from "../SharedModel";
import { ApiUtil } from "../../common_not/ApiUtil";
import { AxiosHost } from "../api/ApiConsts";
import { MIN_DATE } from "../Consts";
import { HomeScreenIndex } from "../Enums";

interface CoreState {
  user?: UserModel;
  accessToken?: string;

  dateISO: string;
  homeScreenIndex: HomeScreenIndex;
}

const initialState: CoreState = {
  dateISO: DateUtils.DateISO(new Date()),
  homeScreenIndex: HomeScreenIndex.Question,
};

interface UpdatePayload {
  user?: UserModel;
  accessToken?: string;
  dateISO?: string;
  homeScreenIndex?: HomeScreenIndex;
}

export const CoreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<UpdatePayload>) => {
      const { user, accessToken, dateISO, homeScreenIndex } = action.payload;
      if (user) {
        state.user = user;
      }
      if (accessToken) {
        state.accessToken = accessToken;
        ApiUtil.SaveApiToken(accessToken);
        AxiosHost.updateCore(accessToken);
      }
      if (dateISO) {
        let newDate = DateUtils.ParseDate(dateISO);
        let today = new Date();
        //IF BEFORE MIN DATE
        let minDate = DateUtils.ParseDate(MIN_DATE);
        if (DateUtils.Before(newDate, minDate)) {
          newDate = minDate;
        } else if (DateUtils.Before(today, newDate)) {
          //IF BEYOND TODAY
          newDate = today;
          return;
        }

        state.dateISO = DateUtils.DateISO(newDate);
      }
      if (homeScreenIndex !== undefined) {
        state.homeScreenIndex = homeScreenIndex;
      }
    },
    reset: (state) => {
      state.user = undefined;
      state.accessToken = undefined;
      state.dateISO = DateUtils.DateISO(new Date());
    },
  },
});

export const CoreActions = CoreSlice.actions;
export const CoreReducer = CoreSlice.reducer;
