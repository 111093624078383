import { Stack, Typography } from "@mui/joy"
import { HelpCenter } from "@mui/icons-material"

interface Props {}

export const NoQuestionView = ({}: Props) => {
  return (
    <Stack spacing={2} alignItems={"center"}>
      <HelpCenter sx={{ fontSize: 70 }} />
      <Typography level="h1" fontSize={40} fontWeight={"md"}>
        Daily Question
      </Typography>
      <Typography level="body-md" textAlign={"center"}>
        There is no question today.
        <br />
        Check out other dates and their questions!
      </Typography>
    </Stack>
  )
}
