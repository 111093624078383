import { useEffect, useState } from "react"
import { ResponseDisplayModel } from "../../../common/SharedModel"
import {
  Alert,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Stack,
  Typography,
} from "@mui/joy"
import React from "react"
import { Paragraphs } from "../../common/Paragraphs"
import { ApiUtil } from "../../../common_not/ApiUtil"
import { ResponseShareType } from "../../../common/SharedEnum"
import { ResponseApi } from "../../../common/api/ResponseApi"
import { CampfireActions } from "../../../common/state/CampfireRedux"
import { useAppDispatch } from "../../../common/state/hooks"
import { DailyQuestionReduxModel } from "../../../common/ModelExtended"

interface Props {
  question: DailyQuestionReduxModel
  response: ResponseDisplayModel
  onClose: () => void
}

export const ResponseEditPanel = ({ question, response, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [shareType, setShareType] = useState(ResponseShareType.Public)

  useEffect(() => {
    setShareType(response.shareType)
  }, [response])

  const onShareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setShareType(ResponseShareType.Public)
    } else {
      setShareType(ResponseShareType.Personal)
    }
  }

  const onClickSubmit = async () => {
    setLoading(true)
    setError("")
    setSuccess("")
    const res = await ApiUtil.ApiWrap(
      ResponseApi.updateResponse(response.id, shareType),
    )
    if (res.success === true) {
      dispatch(
        CampfireActions.update({
          questions: [{ ...question, response: res.response }],
        }),
      )
      setSuccess("Successfully edited response")
    } else {
      setError(res.error)
    }
    setLoading(false)
  }

  let disabled = loading || shareType == response.shareType
  return (
    <React.Fragment>
      <DialogTitle>Edit Response</DialogTitle>
      <DialogContent>
        <Stack spacing={2} paddingBottom={1}>
          <Stack>
            <FormLabel>Question</FormLabel>
            <Typography level="body-sm">
              <Paragraphs text={question.questionText} />
            </Typography>
          </Stack>
          <Stack>
            <FormLabel>Response</FormLabel>
            <Typography level="body-sm">
              <Paragraphs text={response.questionAnswer} />
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <Checkbox
              checked={shareType === ResponseShareType.Public}
              onChange={onShareChange}
              label="Share with others"
              disabled={loading}
            />
          </Stack>
          {error && <Alert color="danger">{error}</Alert>}
          {success && <Alert color="success">{success}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={loading}>
          Close
        </Button>
        <Button onClick={onClickSubmit} loading={loading} disabled={disabled}>
          Update
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}
