import {
  CompleteType,
  ListAccessType,
  ListType,
  ResponseShareType,
} from "./SharedEnum";
import {
  DailyQuestionModel,
  DayInfoModel,
  ItemModel,
  JournalEntryModel,
  JournalTemplateModel,
  ListFrontendModel,
  PersonalJournalModel,
  ReportModel,
  ResponseDBModel,
  ResponseDisplayModel,
  UserModel,
  UserReferralModel,
} from "./SharedModel";

export const UserModelSample: UserModel = {
  id: "string",
  displayName: "string",
  email: "string",
  supportEmail: "string",
  passwordSet: false,
};

export const UserReferralModelSample: UserReferralModel = {
  id: "string",
  referrerUserId: "string",
  referralCode: "string",
};

export const DayInfoModelSample: DayInfoModel = {
  id: "string",
  dateISO: "string",
  questionId: "string",
};

export const DailyQuestionModelSample: DailyQuestionModel = {
  id: "string",
  questionText: "string",
  questionExtension: "string",
};

export const ResponseDBModelSample: ResponseDBModel = {
  id: "string",
  userId: "string",
  questionId: "string",
  questionAnswer: "string",
  shareType: ResponseShareType.Personal,
  timePosted: "string",
};

export const ResponseDisplayModelSample: ResponseDisplayModel = {
  id: "string",
  userDisplayName: "string",
  questionId: "string",
  questionAnswer: "string",
  shareType: ResponseShareType.Personal,
  timePosted: "string",
};

export const ReportModelSample: ReportModel = {
  id: "string",
  userId: "string",
  questionId: "string",
  responseId: "string",
  reason: "string",
};

export const JournalTemplateModelSample: JournalTemplateModel = {
  name: "string",
  description: "",
  prompts: [""],
};

export const PersonalJournalModelSample: PersonalJournalModel = {
  id: "string",
  userId: "string",
  templates: [JournalTemplateModelSample],
};

export const JournalEntryModelSample: JournalEntryModel = {
  id: "string",
  userId: "string",
  dateISO: "string",
  prompts: ["string"],
  responses: ["string"],
};

export const ListFrontendModelSample: ListFrontendModel = {
  id: "string",
  name: "string",
  type: ListType.Ordered,
  completeType: CompleteType.Once,
  accessType: ListAccessType.Editor,
};

export const ItemModelSample: ItemModel = {
  id: "string",
  listId: "string",
  text: "string",
};
