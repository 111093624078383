import { Button, DialogTitle, DialogContent, DialogActions } from "@mui/joy"
import React from "react"
import { UserModel } from "../../common/SharedModel"
import { ApiUtil } from "../../common_not/ApiUtil"

interface Props {
  user: UserModel
  onOpen: () => void
  onClose: () => void
}
export const LogoutPanel = ({ user, onClose }: Props) => {
  const logout = () => {
    ApiUtil.RemoveApiToken()
    window.location.reload()
  }

  return (
    <React.Fragment>
      <DialogTitle>See you later!</DialogTitle>
      <DialogContent>
        You'll need your login code to get back. Contact me at
        {" " + user.supportEmail} if you lost it.
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button onClick={logout}>Logout</Button>
      </DialogActions>
    </React.Fragment>
  )
}
