import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUtil } from "../../common_not/ApiUtil";
import { QuestionApi } from "../api/QuestionApi";
import { CampfireActions } from "./CampfireRedux";
import { ResponseApi } from "../api/ResponseApi";

interface Payload {
  dateISO: string;
  replace: boolean;
}
export const questionThunk = createAsyncThunk(
  "campfire/questionThunk",
  async (payload: Payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { dateISO, replace } = payload;

    if (replace) {
      dispatch(CampfireActions.clearQuestion({ dateISO: dateISO }));
    }

    dispatch(
      CampfireActions.update({
        questionApiState: [
          {
            dateISO: dateISO,
            loading: true,
            sucess: false,
            error: "",
          },
        ],
      })
    );
    const res = await ApiUtil.ApiWrap(QuestionApi.getQuestion(dateISO));
    if (res.success) {
      let questions = [];
      let dayInfos = [];
      if (res.response !== undefined) {
        questions.push({
          ...res.response.question,
          response: res.response.response,
          totalCount: res.response.totalCount,
        });
        dayInfos.push(res.response.dayInfo);
      }
      dispatch(
        CampfireActions.update({
          questions: questions,
          dayInfos: dayInfos,
          questionApiState: [
            {
              dateISO: dateISO,
              loading: false,
              sucess: true,
              error: "",
            },
          ],
        })
      );
    } else {
      dispatch(
        CampfireActions.update({
          questionApiState: [
            {
              dateISO: dateISO,
              loading: false,
              sucess: false,
              error: res.error,
            },
          ],
        })
      );
    }
  }
);

interface IResponseThunkPayload {
  dateISO: string;
  questionId: string;
}
export const responseThunk = createAsyncThunk(
  "campfire/responseThunk",
  async ({ dateISO, questionId }: IResponseThunkPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(
      CampfireActions.update({
        responseApiState: [
          {
            dateISO: dateISO,
            loading: true,
            sucess: false,
            error: "",
          },
        ],
      })
    );
    const res = await ApiUtil.ApiWrap(ResponseApi.getResponses(questionId));
    if (res.success) {
      dispatch(
        CampfireActions.update({
          responses: res.response.responses,
          responseApiState: [
            {
              dateISO: dateISO,
              loading: false,
              sucess: true,
              error: "",
            },
          ],
        })
      );
    } else {
      dispatch(
        CampfireActions.update({
          responseApiState: [
            {
              dateISO: dateISO,
              loading: false,
              sucess: false,
              error: res.error,
            },
          ],
        })
      );
    }
  }
);
