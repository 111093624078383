import axios from "axios";
import { GenerateApiBase } from "../../common_not/ApiUtil";

export const ApiBase = GenerateApiBase();

export class AxiosHost {
  static Core = axios.create({
    baseURL: ApiBase,
    timeout: 100000,
    withCredentials: true,
  });

  static updateCore(apiToken: string) {
    this.Core = axios.create({
      baseURL: ApiBase,
      timeout: 100000,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
  }
}
