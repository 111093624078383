import { useState } from "react"
import { produce } from "immer"
import {
  Alert,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy"
import { Delete } from "@mui/icons-material"
import { JournalTemplateModel } from "../../common/SharedModel"

interface Props {
  initTemplate: JournalTemplateModel
  saveTemplate: (template: JournalTemplateModel) => void
  onDeleteTemplate: (template: JournalTemplateModel) => void
}

export const JournalTemplateEditView = ({
  initTemplate,
  saveTemplate,
  onDeleteTemplate,
}: Props) => {
  const [name, setName] = useState(initTemplate.name)
  const [description, setDescription] = useState(initTemplate.description)
  const [prompts, setPrompts] = useState(initTemplate.prompts)

  const onEditPrompt = (index: number, prompt: string) => {
    const newPrompts = produce(prompts, (draft) => {
      draft[index] = prompt
    })
    setPrompts(newPrompts)
  }

  const onDeletePrompt = (index: number) => {
    const newPrompts = produce(prompts, (draft) => {
      draft.splice(index, 1)
    })
    setPrompts(newPrompts)
  }

  const onAddPrompt = () => {
    const newPrompts = produce(prompts, (draft) => {
      draft.push("")
    })
    setPrompts(newPrompts)
  }

  const onClickSave = () => {
    const newTemplate: JournalTemplateModel = {
      name,
      description,
      prompts,
    }

    saveTemplate(newTemplate)
  }

  const onClickCancel = () => {
    saveTemplate(initTemplate)
  }

  const onClickDelete = () => {
    onDeleteTemplate(initTemplate)
  }

  var views = prompts.map((p, i) => {
    return (
      <PromptEditView
        key={i}
        initPrompt={p}
        index={i}
        onEditPrompt={onEditPrompt}
        onDeletePrompt={onDeletePrompt}
      />
    )
  })
  if (views.length == 0) {
    views.push(<Alert key="None">No Questions.</Alert>)
  }

  const noSave = !name || prompts.includes("")

  return (
    <Stack spacing={2}>
      <Typography level="h3">Edit Journal</Typography>
      <Stack direction="row" justifyContent={"space-between"}>
        <Button onClick={onClickDelete} variant="outlined" color="danger">
          Delete
        </Button>
        <Stack direction={"row"} spacing={2}>
          <Button onClick={onClickCancel} variant="outlined">
            Cancel
          </Button>
          <Button onClick={onClickSave} disabled={noSave}>
            Save
          </Button>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography level="title-md">Journal Name</Typography>
        <Textarea
          placeholder={`Name your journal`}
          onChange={(ev) => setName(ev.target.value)}
          value={name}
          variant="outlined"
        />
      </Stack>
      <Stack spacing={1}>
        <Typography level="title-md">Journal Description</Typography>
        <Textarea
          placeholder={`Describe your journal`}
          onChange={(ev) => setDescription(ev.target.value)}
          value={description}
          variant="outlined"
        />
      </Stack>
      <Stack spacing={1}>
        <Typography level="title-md">Journal Questions</Typography>
        <Button onClick={onAddPrompt} variant="outlined">
          Add Question
        </Button>
        {views}
      </Stack>
    </Stack>
  )
}

interface EditProps {
  initPrompt: string
  index: number
  onEditPrompt: (index: number, prompt: string) => void
  onDeletePrompt: (index: number) => void
}
const PromptEditView = ({
  initPrompt,
  index,
  onEditPrompt,
  onDeletePrompt,
}: EditProps) => {
  const [prompt, setPrompt] = useState(initPrompt)

  const onClickDelete = () => {
    onDeletePrompt(index)
  }

  const onUpdateText = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(ev.target.value)
    onEditPrompt(index, ev.target.value)
  }

  return (
    <Card variant="soft">
      <CardContent>
        <Stack>
          <Stack direction="row" justifyContent={"space-between"}>
            <Typography>Question {index + 1}</Typography>
            <IconButton onClick={onClickDelete} size={"md"}>
              <Delete />
            </IconButton>
          </Stack>
          <Textarea
            placeholder={`Put your custom question here`}
            onChange={onUpdateText}
            value={prompt}
            variant="outlined"
          />
        </Stack>
      </CardContent>
    </Card>
  )
}
