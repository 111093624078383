import { useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../common/state/hooks"
import { JournalSelector } from "../../common/state/store"
import {
  JournalEntryModel,
  JournalTemplateModel,
  PersonalJournalModel,
} from "../../common/SharedModel"
import {
  Alert,
  Button,
  Card,
  CardContent,
  Link,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy"
import { produce } from "immer"
import { JournalApi } from "../../common/api/JournalApi"
import { JournalActions } from "../../common/state/JournalRedux"
import { ApiUtil } from "../../common_not/ApiUtil"
import { CreateJournalView, JournalCreateView } from "./JournalCreateView"
import { Paragraphs } from "../common/Paragraphs"
import React from "react"
import { ShowEntryView } from "./entry/ShowEntryView"

interface Props {
  journal: PersonalJournalModel
  dateISO: string
}

export const JournalEntryView = ({ journal, dateISO }: Props) => {
  const entry = useAppSelector(JournalSelector.entry(dateISO))
  const [editView, setEditView] = useState(false)
  const [editEntry, setEditEntry] = useState(false)

  useEffect(() => {
    setEditView(false)
    setEditEntry(false)
  }, [dateISO])

  const openJournalEditView = () => {
    setEditView(true)
  }

  const closeJournalEditView = () => {
    setEditView(false)
  }

  if (editView) {
    return (
      <CreateJournalView
        initTemplates={journal.templates}
        initView={JournalCreateView.Templates}
        closeEditView={closeJournalEditView}
      />
    )
  } else if (entry) {
    if (editEntry) {
      return (
        <EditEntryView
          initPrompts={entry.prompts}
          initResponses={entry.responses}
          journal={journal}
          dateISO={dateISO}
          openEditView={openJournalEditView}
          setEditEntry={setEditEntry}
        />
      )
    } else {
      return <ShowEntryView entry={entry} setEditEntry={setEditEntry} />
    }
  } else {
    return (
      <EditEntryView
        initPrompts={[]}
        initResponses={[]}
        journal={journal}
        dateISO={dateISO}
        openEditView={openJournalEditView}
      />
    )
  }
}

interface EditEntryViewProps {
  initPrompts: string[]
  initResponses: string[]
  dateISO: string
  journal: PersonalJournalModel
  openEditView: () => void
  setEditEntry?: React.Dispatch<React.SetStateAction<boolean>>
}
const EditEntryView = ({
  initPrompts,
  initResponses,
  dateISO,
  journal,
  openEditView,
  setEditEntry,
}: EditEntryViewProps) => {
  const dispatch = useAppDispatch()

  const [selectedTemplate, setSelectedtemplate] =
    useState<JournalTemplateModel>()
  const [responses, setResponses] = useState<string[]>(initResponses)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  useMemo(() => {
    if (initResponses.length > 0) {
      setSelectedtemplate({
        name: "placeholder",
        description: "placehoder",
        prompts: initPrompts,
      })
    } else {
      setSelectedtemplate(undefined)
    }
    setResponses(initResponses)
  }, [initPrompts, initResponses])

  const onSelectTemplate = (index: number) => {
    setResponses(
      Array(Math.max(journal.templates[index].prompts.length, 1))
        .join(".")
        .split("."),
    )
    setSelectedtemplate(journal.templates[index])
  }

  const onChangeTemplate = () => {
    setSelectedtemplate(undefined)
  }

  if (!selectedTemplate) {
    return (
      <Stack spacing={2}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography level="h3">Select a Journal</Typography>
          <Button onClick={openEditView} variant="outlined">
            Edit Journals
          </Button>
        </Stack>
        <Card variant="soft">
          <CardContent>
            <Typography level="title-md">
              <Link
                overlay
                underline="none"
                // href="#interactive-card"
                // sx={{ color: "text.tertiary" }}
                onClick={() =>
                  onSelectTemplate(
                    getRandomInt(0, journal.templates.length - 1),
                  )
                }
              >
                Surprise me with a random one!
              </Link>
            </Typography>
          </CardContent>
        </Card>
        {journal.templates.map((x, index) => (
          <Card key={x.name + index} variant="soft">
            <CardContent>
              <Stack spacing={2}>
                <Stack>
                  <Typography level="title-md">
                    <Link
                      overlay
                      underline="none"
                      // href="#interactive-card"
                      // sx={{ color: "text.tertiary" }}
                      onClick={() => onSelectTemplate(index)}
                    >
                      {x.name}
                    </Link>
                  </Typography>
                  {x.description && <Paragraphs text={x.description} />}
                </Stack>
                <Stack>
                  <Typography level="title-md">Questions</Typography>
                  {x.prompts.length < 1 ? (
                    <Typography>None</Typography>
                  ) : (
                    <React.Fragment>
                      {x.prompts.map((p) => (
                        <Typography key={p}>{p}</Typography>
                      ))}
                    </React.Fragment>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>
    )
  }

  const onSaveEntry = async () => {
    setError("")
    setLoading(true)
    const res = await ApiUtil.ApiWrap(
      JournalApi.createUpdateEntry(
        dateISO,
        selectedTemplate.prompts,
        responses,
      ),
    )
    if (res.success) {
      dispatch(
        JournalActions.update({
          entries: [res.response.entry],
        }),
      )
    } else {
      setError(res.error)
    }
    setLoading(false)
    setEditEntry && setEditEntry(false)
  }

  const onUpdateText =
    (index: number) => (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newResponses = produce(responses, (draft) => {
        draft[index] = ev.target.value
      })
      setResponses(newResponses)
    }

  let views = []
  for (let i = 0; i < selectedTemplate.prompts.length; i++) {
    views.push(
      <Stack key={selectedTemplate.prompts[i]} spacing={1}>
        <Typography level={"title-lg"}>
          {selectedTemplate.prompts[i]}
        </Typography>
        <Textarea
          disabled={loading}
          placeholder={`Response here`}
          onChange={onUpdateText(i)}
          value={responses[i]}
          variant="outlined"
        />
      </Stack>,
    )
  }
  if (views.length === 0) {
    views.push(
      <Stack key={"empty"} spacing={1}>
        <Textarea
          disabled={loading}
          placeholder={`Write your journal here!`}
          onChange={onUpdateText(0)}
          value={responses[0]}
          minRows={5}
          variant="outlined"
        />
      </Stack>,
    )
  }

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography level="h3">Write Journal Entry</Typography>
        {!setEditEntry && (
          <Button onClick={onChangeTemplate} variant="outlined">
            Change Template
          </Button>
        )}
      </Stack>
      {views}
      {error && <Alert color="danger">{error}</Alert>}
      {setEditEntry ? (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Button
            onClick={() => setEditEntry(false)}
            disabled={loading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={onSaveEntry} disabled={loading} loading={loading}>
            Save Entry
          </Button>
        </Stack>
      ) : (
        <Button onClick={onSaveEntry} disabled={loading} loading={loading}>
          Save Entry
        </Button>
      )}
    </Stack>
  )
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
