import {
  ResponseDisplayModelSample,
  ResponseDBModelSample,
} from "../ModelSample";
import { ResponseShareType } from "../SharedEnum";
import { ResponseDisplayModel, ResponseDBModel } from "../SharedModel";
import { Util } from "../Util";
import { AxiosHost } from "./ApiConsts";

interface GetResponseApiResponse {
  responses: ResponseDisplayModel[];
}

const GetResponseApiResponseSample: GetResponseApiResponse = {
  responses: [ResponseDisplayModelSample],
};

type SubmitRsponseApiResponseType =
  | { success: true; response: ResponseDBModel }
  | { success: false; error: string };

type GetResponseApiResponseType =
  | { success: true; response: GetResponseApiResponse }
  | { success: false; error: string };

export const ResponseApi = {
  submitResponse: async (
    dateISO: string,
    questionId: string,
    answer: string,
    shareType: ResponseShareType,
    timePosted: string
  ): Promise<SubmitRsponseApiResponseType> => {
    const formData = new FormData();
    formData.append("dateISO", dateISO);
    formData.append("questionId", questionId);
    formData.append("answer", answer);
    formData.append("shareType", shareType.toString());
    formData.append("timePosted", timePosted);

    let response = await AxiosHost.Core({
      url: `response/submit`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (Util.is<ResponseDBModel>(response.data, ResponseDBModelSample)) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  updateResponse: async (
    responseId: string,
    shareType: ResponseShareType
  ): Promise<SubmitRsponseApiResponseType> => {
    const formData = new FormData();
    formData.append("responseId", responseId);
    formData.append("shareType", shareType.toString());

    let response = await AxiosHost.Core({
      url: `response/update`,
      method: "PUT",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (Util.is<ResponseDBModel>(response.data, ResponseDBModelSample)) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
  getResponses: async (
    questionId: string
  ): Promise<GetResponseApiResponseType> => {
    let response = await AxiosHost.Core({
      url: `response/responses`,
      method: "GET",
      params: { questionId },
    });
    if (
      Util.is<GetResponseApiResponse>(
        response.data,
        GetResponseApiResponseSample
      )
    ) {
      return { success: true, response: response.data };
    } else {
      return { success: false, error: "Does not match" };
    }
  },
};
