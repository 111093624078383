import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DayInfoModel, ResponseDisplayModel } from "../SharedModel";
import { CampfireState } from "./CampfireModel";
import { DailyQuestionReduxModel } from "../ModelExtended";
import { ObjUtil } from "../ObjUtil";
import { ApiState } from "./SharedModels";

const initialState: CampfireState = {
  dayInfos: {},
  questions: {},
  responses: {},
  questionApiState: {},
  responseApiState: {},
};

export interface UpdatePayload {
  dayInfos?: DayInfoModel[];
  questions?: DailyQuestionReduxModel[];
  responses?: ResponseDisplayModel[];
  questionApiState?: ApiState[];
  responseApiState?: ApiState[];
}

export interface ClearPayload {
  dateISO: string;
}

export const CampfireSlice = createSlice({
  name: "campfire",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<UpdatePayload>) => {
      const {
        responses,
        dayInfos,
        questions,
        questionApiState,
        responseApiState,
      } = action.payload;
      if (responses) {
        for (let x of responses) {
          state.responses[x.id] = x;
        }
      }
      if (dayInfos) {
        for (let x of dayInfos) {
          state.dayInfos[x.dateISO] = x;
        }
      }
      if (questions) {
        for (let x of questions) {
          state.questions[x.id] = x;
        }
      }
      if (questionApiState) {
        for (let x of questionApiState) {
          state.questionApiState[x.dateISO] = x;
        }
      }
      if (responseApiState) {
        for (let x of responseApiState) {
          state.responseApiState[x.dateISO] = x;
        }
      }
    },
    clearQuestion: (state, action: PayloadAction<ClearPayload>) => {
      const { dateISO } = action.payload;
      const dayInfo = state.dayInfos[dateISO];
      const question = state.questions[dayInfo?.id || ""];
      if (question !== undefined) {
        delete state.dayInfos[dayInfo?.dateISO || ""];
        delete state.questions[question.id];
        for (const response of ObjUtil.values(state.questions)) {
          delete state.responses[response.id];
        }
      }
      delete state.questionApiState[dateISO];
      delete state.responseApiState[dateISO];
    },
    delete: (state, action: PayloadAction<UpdatePayload>) => {
      const { responses, dayInfos, questions } = action.payload;
      if (responses) {
        for (let x of responses) {
          delete state.responses[x.id];
        }
      }
      if (dayInfos) {
        for (let x of dayInfos) {
          delete state.dayInfos[x.dateISO];
        }
      }
      if (questions) {
        for (let x of questions) {
          delete state.questions[x.id];
        }
      }
    },
    reset: (state) => {
      state.questions = {};
      state.responses = {};
      state.questionApiState = {};
      state.responseApiState = {};
    },
  },
});

export const CampfireActions = CampfireSlice.actions;
export const CampfireReducer = CampfireSlice.reducer;
