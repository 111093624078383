import React, { useEffect, useState } from "react"
import { AuthDialog } from "./AuthDialog"
import { useAppDispatch, useAppSelector } from "../common/state/hooks"
import { CoreSelector } from "../common/state/store"
import {
  Container,
  Grid,
  IconButton,
  Stack,
  Tab,
  TabList,
  Tabs,
  Typography,
  tabClasses,
} from "@mui/joy"
import { ResponseView } from "./dailyquestion/response/ResponseView"
import { useNavigate, useParams } from "react-router-dom"
import { DateUtils } from "../common/DateUtils"
import { QuestionView } from "./dailyquestion/question/QuestionView"
import { MIN_DATE } from "../common/Consts"
import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import {
  addDays,
  addMonths,
  addWeeks,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns"
import { DropdownMenu } from "./menu/DropdownMenu"
import { JournalView } from "./journal/JournalView"
import { DaysViewMode, ViewMode, createURL } from "./common/Navigate"
import { QuestionListView } from "./dailyquestion/QuestionListView"
import { JournalListView } from "./journal/JournalListView"
import { DatePanel } from "./menu/DatePanel"
import { MonthView } from "./dailyquestion/MonthView"

interface Props {
  viewMode: ViewMode
  daysViewMode?: DaysViewMode
}
export const DesktopHome = ({
  viewMode,
  daysViewMode = DaysViewMode.Day,
}: Props) => {
  const navigate = useNavigate()
  const { date } = useParams()
  const [dateObj, setDateObj] = useState(new Date())
  const user = useAppSelector(CoreSelector.user)

  useEffect(() => {
    let newDate = new Date()
    if (date) {
      newDate = DateUtils.ParseDate(date)
    }

    if (DateUtils.DaySame(newDate, dateObj)) {
      return
    }

    //IF BEFORE MIN DATE
    if (DateUtils.Before(newDate, DateUtils.ParseDate(MIN_DATE))) {
      navigate(createURL({ dateISO: MIN_DATE, viewMode, daysViewMode }))
      return
    }

    //IF BEYOND TODAY
    if (DateUtils.Before(new Date(), newDate)) {
      navigate(
        createURL({
          dateISO: DateUtils.DateISO(new Date()),
          viewMode,
          daysViewMode,
        }),
      )
      return
    }

    setDateObj(newDate)
  }, [date])

  return (
    <React.Fragment>
      {!user && <AuthDialog />}
      <AuthHome
        date={dateObj}
        viewMode={viewMode}
        daysViewMode={daysViewMode}
      />
    </React.Fragment>
  )
}

interface IAuthHome {
  date: Date
  viewMode: ViewMode
  daysViewMode: DaysViewMode
}
const AuthHome = ({ date, viewMode, daysViewMode }: IAuthHome) => {
  const user = useAppSelector(CoreSelector.user)

  if (user === undefined) {
    return <div />
  }

  const isToday = DateUtils.DateISO(date) === DateUtils.DateISO(new Date())
  const isMinDate = DateUtils.DateISO(date) === MIN_DATE

  let body
  if (daysViewMode == DaysViewMode.Month) {
    body = <MonthView startDate={date} viewMode={viewMode} />
  } else if (daysViewMode == DaysViewMode.Week) {
    if (viewMode == ViewMode.PersonalJournal) {
      body = <JournalListView startDate={date} />
    } else {
      body = <QuestionListView startDate={date} />
    }
  } else {
    if (viewMode == ViewMode.PersonalJournal) {
      body = <JournalView date={date} />
    } else {
      body = (
        <React.Fragment>
          <Typography level="h2" fontWeight={"sm"}>
            {DateUtils.DisplayDate(date)}
          </Typography>
          <QuestionView date={date} isToday={isToday} isMinDate={isMinDate} />
          <ResponseView date={date} isToday={isToday} />
        </React.Fragment>
      )
    }
  }

  return (
    <Container>
      <Stack paddingBottom={5}>
        <Header
          date={date}
          isToday={isToday}
          isMinDate={isMinDate}
          viewMode={viewMode}
          daysViewMode={daysViewMode}
        />
        {body}
      </Stack>
    </Container>
  )
}

interface HeaderProps {
  date: Date
  isToday: boolean
  isMinDate: boolean
  viewMode: ViewMode
  daysViewMode: DaysViewMode
}
const Header = ({
  date,
  isToday,
  isMinDate,
  viewMode,
  daysViewMode,
}: HeaderProps) => {
  const dateISO = DateUtils.DateISO(date)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const user = useAppSelector(CoreSelector.user)

  const onDatePrevious = () => {
    let next
    switch (daysViewMode) {
      case DaysViewMode.Month:
        next = subMonths(date, 1)
        break
      case DaysViewMode.Week:
        next = subWeeks(date, 1)
        break
      default:
      case DaysViewMode.Day:
        next = subDays(date, 1)
        break
    }
    navigate(
      createURL({ dateISO: DateUtils.DateISO(next), viewMode, daysViewMode }),
    )
  }
  const onDateNext = () => {
    let next
    switch (daysViewMode) {
      case DaysViewMode.Month:
        next = addMonths(date, 1)
        break
      case DaysViewMode.Week:
        next = addWeeks(date, 1)
        break
      default:
      case DaysViewMode.Day:
        next = addDays(date, 1)
        break
    }
    navigate(
      createURL({ dateISO: DateUtils.DateISO(next), viewMode, daysViewMode }),
    )
  }

  return (
    <Stack paddingTop={1} spacing={1} paddingBottom={1}>
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid md={2} xs={6} order={{ md: 1, xs: 1 }}>
          <Stack direction={"row"} spacing={1} paddingBottom={1}>
            <IconButton
              onClick={onDatePrevious}
              disabled={isMinDate}
              size={"md"}
            >
              <ChevronLeft />
            </IconButton>
            <DatePanel
              currentDate={date}
              viewMode={viewMode}
              daysViewMode={daysViewMode}
            />
            <IconButton onClick={onDateNext} disabled={isToday} size={"md"}>
              <ChevronRight />
            </IconButton>
          </Stack>
        </Grid>
        <Grid md={8} xs={12} order={{ md: 2, xs: 3 }}>
          <Stack spacing={1} direction={"column"}>
            <ViewModeTabs
              dateISO={dateISO}
              viewMode={viewMode}
              daysViewMode={daysViewMode}
            />
            <DaysViewModeTabs
              dateISO={dateISO}
              viewMode={viewMode}
              daysViewMode={daysViewMode}
            />
          </Stack>
        </Grid>
        <Grid md={2} xs={6} order={{ md: 3, xs: 2 }}>
          <Stack alignItems={"flex-end"}>
            {user && <DropdownMenu user={user} />}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

interface ViewModeTabsProps {
  dateISO: string
  viewMode: ViewMode
  daysViewMode: DaysViewMode
}
const ViewModeTabs = ({
  dateISO,
  viewMode,
  daysViewMode,
}: ViewModeTabsProps) => {
  const navigate = useNavigate()

  const onChange = (
    _: React.SyntheticEvent<Element, Event> | null,
    value: string | number | null,
  ) => {
    if (value === ViewMode.DailyQuestion) {
      navigate(
        createURL({ dateISO, viewMode: ViewMode.DailyQuestion, daysViewMode }),
      )
    } else if (value === ViewMode.PersonalJournal) {
      navigate(
        createURL({
          dateISO,
          viewMode: ViewMode.PersonalJournal,
          daysViewMode,
        }),
      )
    }
  }
  return (
    <Tabs
      aria-label="tabs"
      sx={{ bgcolor: "transparent" }}
      value={viewMode}
      onChange={onChange}
    >
      <TabList
        tabFlex="auto"
        disableUnderline
        sx={{
          p: 0.5,
          gap: 0.5,
          borderRadius: "xl",
          bgcolor: "background.level1",
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            boxShadow: "sm",
            bgcolor: "background.surface",
          },
        }}
      >
        <Tab disableIndicator value={ViewMode.DailyQuestion}>
          Daily Question
        </Tab>
        <Tab disableIndicator value={ViewMode.PersonalJournal}>
          Personal Journal
        </Tab>
      </TabList>
    </Tabs>
  )
}

const DaysViewModeTabs = ({
  dateISO,
  viewMode,
  daysViewMode,
}: ViewModeTabsProps) => {
  const navigate = useNavigate()

  const onChange = (
    _: React.SyntheticEvent<Element, Event> | null,
    value: string | number | null,
  ) => {
    let newViewMode = DaysViewMode.Day
    if (value === DaysViewMode.Week) {
      newViewMode = DaysViewMode.Week
    } else if (value === DaysViewMode.Month) {
      newViewMode = DaysViewMode.Month
    }

    navigate(
      createURL({
        dateISO,
        viewMode: viewMode,
        daysViewMode: newViewMode,
      }),
    )
  }
  return (
    <Tabs
      aria-label="tabs"
      sx={{ bgcolor: "transparent" }}
      value={daysViewMode}
      onChange={onChange}
    >
      <TabList
        tabFlex="auto"
        disableUnderline
        sx={{
          p: 0.5,
          gap: 0.5,
          borderRadius: "xl",
          bgcolor: "background.level1",
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            boxShadow: "sm",
            bgcolor: "background.surface",
          },
        }}
      >
        <Tab disableIndicator value={DaysViewMode.Day}>
          Day
        </Tab>
        <Tab disableIndicator value={DaysViewMode.Week}>
          Week
        </Tab>
        <Tab disableIndicator value={DaysViewMode.Month}>
          Month
        </Tab>
      </TabList>
    </Tabs>
  )
}
