import {
  Button,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  DialogActions,
  Input,
  Alert,
  FormLabel,
} from "@mui/joy"
import React, { useEffect, useState } from "react"
import { ApiUtil } from "../../common_not/ApiUtil"
import { CampfireActions } from "../../common/state/CampfireRedux"
import { UserApi } from "../../common/api/UserApi"
import { useDispatch } from "react-redux"
import { UserModel } from "../../common/SharedModel"
import { AppName } from "../../common/Consts"
import { CoreActions } from "../../common/state/CoreRedux"

interface Props {
  user: UserModel
  onOpen: () => void
  onClose: () => void
}
export const ProfilePanel = ({ user, onClose }: Props) => {
  const dispatch = useDispatch()
  const [displayName, setName] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    setEmail(user.email)
    setName(user.displayName)
  }, [user])

  const onUpdateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }
  const onUpdateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const onClickSubmit = async () => {
    setLoading(true)
    setError("")
    const response = await ApiUtil.ApiWrap(UserApi.update(displayName, email))
    if (response.success === true) {
      dispatch(
        CoreActions.update({
          user: response.response.user,
          accessToken: response.response.token,
        }),
      )
    } else {
      setError(response.error)
    }
    setLoading(false)
  }

  const disable =
    !email ||
    !displayName ||
    (email === user.email && displayName === user.displayName)

  return (
    <React.Fragment>
      <DialogTitle>Profile</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <FormLabel>Display Name</FormLabel>
            <Typography level="body-sm">
              Your display name will be shown next to your responses if you
              share them with others. <br />
              Please choose carefully if you wish to remain anonymous.
            </Typography>
            <Input
              fullWidth
              onChange={onUpdateName}
              value={displayName}
              variant="outlined"
            />
          </Stack>
          <Stack spacing={1}>
            <FormLabel>Contact Email</FormLabel>
            <Typography level="body-sm">
              This email will only be used to contact you with updates and
              announcements regarding {AppName}. <br />
              In the case that {AppName} is discontinued, this information will
              be promptly deleted.
            </Typography>
            <Input
              fullWidth
              onChange={onUpdateEmail}
              value={email}
              variant="outlined"
            />
          </Stack>
          {error && <Alert color="danger">{error}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={loading}>
          Close
        </Button>
        <Button onClick={onClickSubmit} loading={loading} disabled={disable}>
          Update
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}
