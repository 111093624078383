import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ItemModel,
  JournalEntryModel,
  ListFrontendModel,
  PersonalJournalModel,
} from "../SharedModel";
import { JournalState } from "./JournalModel";
import { ApiState } from "./SharedModels";
import { ListState } from "./ListModel";
import { ObjUtil } from "../ObjUtil";

const initialState: ListState = {
  list: {},
  item: {},
  apiStateByListId: {},
};

interface UpdatePayload {
  lists?: ListFrontendModel[];
  items?: ItemModel[];
  apiState?: ApiState[];
}

interface ClearPayload {
  listId: string;
}

export const ListSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<UpdatePayload>) => {
      const { lists, items, apiState } = action.payload;
      if (lists) {
        for (let x of lists) {
          state.list[x.id] = x;
        }
      }
      if (items) {
        for (let x of items) {
          state.item[x.id] = x;
        }
      }
      if (apiState) {
        for (let x of apiState) {
          state.apiStateByListId[x.dateISO] = x;
        }
      }
    },
    clear: (state, action: PayloadAction<ClearPayload>) => {
      const { listId } = action.payload;
      delete state.list[listId];
      for (var x of ObjUtil.values(state.item)) {
        if (x.listId === listId) {
          delete state.item[x.id];
        }
      }
    },
    reset: (state) => {
      state.list = {};
      state.item = {};
      state.apiStateByListId = {};
    },
    delete: (state, action: PayloadAction<UpdatePayload>) => {
      const { lists, items } = action.payload;
      if (lists) {
        for (let x of lists) {
          delete state.list[x.id];
        }
      }
      if (items) {
        for (let x of items) {
          delete state.item[x.id];
        }
      }
    },
  },
});

export const ListActions = ListSlice.actions;
export const ListReducer = ListSlice.reducer;
