import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy"
import { useEffect, useState } from "react"
import { DateUtils } from "../common/DateUtils"
import { ApiUtil } from "../common_not/ApiUtil"
import { GetQuestionApiResponse, QuestionApi } from "../common/api/QuestionApi"
import { addDays } from "date-fns"
import React from "react"
import { QuestionDisplay } from "./dailyquestion/question/QuestionView"

export const DebugHome = () => {
  const [passcode, setPasscode] = useState("")
  const [passcodeSet, setPasscodeSet] = useState(false)
  const [date, setDate] = useState(DateUtils.DateISO(new Date()))
  const [daysAhead, setDaysAhead] = useState(0)
  const [question, setQuestion] = useState("")
  const [questionExtension, setQuestionExtension] = useState("")
  const [newQuestion, setNewQuestion] = useState(false)
  const [confirmDelete, setConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [todayQuestion, setTodayQuestion] = useState<GetQuestionApiResponse>()

  useEffect(() => {
    if (passcodeSet) {
      getQuestion()
    }
  }, [date])

  useEffect(() => {
    if (todayQuestion) {
      setQuestion(todayQuestion.question.questionText)
      setQuestionExtension(todayQuestion.question.questionExtension)
    } else {
      setQuestion("")
      setQuestionExtension("")
    }
  }, [todayQuestion])

  const onClickSetPasscode = () => {
    setPasscodeSet(true)
    getQuestion()
  }

  const onRefresh = () => {
    setError("")
    setSuccess("")
    setLoading(false)
    getQuestion()
  }

  const getQuestion = async () => {
    setLoading(true)
    setError("")
    setTodayQuestion(undefined)
    var response = await ApiUtil.ApiWrap(
      QuestionApi.getQuestionDebug(passcode, date),
    )
    if (response.success) {
      setTodayQuestion(response.response)
    } else {
      setError(response.error)
    }
    setLoading(false)
  }

  const onPassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasscode(event.target.value)
  }
  const onQuestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(event.target.value)
  }
  const onQuestionExtensionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setQuestionExtension(event.target.value)
  }

  const onDatePrevious = () => {
    let now = DateUtils.ParseDate(date)
    let next = addDays(now, -1)
    setDate(DateUtils.DateISO(next))
  }
  const onDateNext = () => {
    let now = DateUtils.ParseDate(date)
    let next = addDays(now, 1)
    setDate(DateUtils.DateISO(next))
  }
  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value)
  }

  const onDaysAheadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let days = parseInt(event.target.value)
    if (days >= 0) {
      setDaysAhead(days)
    }
  }

  const onConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirm(event.target.checked)
  }

  const onNewQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewQuestion(event.target.checked)
  }

  const submitResponse = async () => {
    setLoading(true)
    setError("")
    const res = await ApiUtil.ApiWrap(
      QuestionApi.createQuestion(
        passcode,
        date,
        daysAhead,
        question,
        questionExtension,
        newQuestion,
        todayQuestion?.question.id,
      ),
    )
    if (res.success) {
      setSuccess(`Successfully created ${date}`)
    } else {
      setError(res.error)
    }
    setLoading(false)
    onRefresh()
  }

  const deleteQuestion = async () => {
    setLoading(true)
    setError("")
    const res = await ApiUtil.ApiWrap(
      QuestionApi.deleteQuestion(passcode, date, confirmDelete),
    )
    if (res.success) {
      setSuccess(`Successfully created ${date}`)
    } else {
      setError(res.error)
    }
    setLoading(false)
    onRefresh()
  }

  let body = <div />
  if (loading) {
    body = <CircularProgress />
  } else if (passcodeSet) {
    body = (
      <React.Fragment>
        <Stack direction={"row"} spacing={10} alignItems={"flex-end"}>
          <Stack>
            <FormLabel>Date</FormLabel>
            <Stack direction={"row"} spacing={1}>
              <Button onClick={onDatePrevious} disabled={loading}>
                Previous Day
              </Button>
              <Input
                type="date"
                value={date}
                slotProps={{
                  input: {
                    min: "2023-09-20",
                    max: "2024-09-20",
                  },
                }}
                onChange={onDateChange}
                disabled={loading}
              />
              <Button onClick={onDateNext} disabled={loading}>
                Next Day
              </Button>
            </Stack>
          </Stack>
          <Stack>
            <FormLabel>Days Ahead</FormLabel>
            <Input
              value={daysAhead}
              onChange={onDaysAheadChange}
              disabled={loading}
            />
          </Stack>
          <Button onClick={onRefresh}>Refresh</Button>
          <Stack direction={"row"} spacing={1}>
            <Button
              onClick={deleteQuestion}
              color="danger"
              disabled={loading || todayQuestion === undefined}
            >
              Delete Question and Responses (CANNOT UNDO)
            </Button>
            <Checkbox
              checked={confirmDelete}
              onChange={onConfirmChange}
              label="Confirm Delete"
              disabled={loading || todayQuestion === undefined}
            />
          </Stack>
        </Stack>
        <FormLabel>Question</FormLabel>
        <Textarea
          value={question}
          onChange={onQuestionChange}
          minRows={2}
          disabled={loading}
        />
        <FormLabel>Question Extension</FormLabel>
        <Textarea
          value={questionExtension}
          onChange={onQuestionExtensionChange}
          minRows={2}
          disabled={loading}
        />
        <Checkbox
          checked={newQuestion}
          onChange={onNewQuestionChange}
          label="Create New Question"
          disabled={loading}
        />
        <Button onClick={submitResponse}>Create/Update</Button>
        {loading && <CircularProgress />}
        {error && <Alert color="danger">{error}</Alert>}
        {success && <Alert color="success">{success}</Alert>}
        <Stack paddingTop={4}>
          <Typography level={"h3"}>Preview Below</Typography>
          <QuestionDisplay
            question={{
              id: "string",
              questionText: question,
              questionExtension: questionExtension,
              totalCount: 0,
            }}
          />
        </Stack>
      </React.Fragment>
    )
  }

  return (
    <Container>
      <Typography level="h1">Debug Page</Typography>
      <Stack spacing={1}>
        <FormLabel>Passcode</FormLabel>
        <Stack direction={"row"} spacing={1}>
          <Input value={passcode} onChange={onPassChange} disabled={loading} />
          <Button onClick={onClickSetPasscode}>Set Passcode</Button>
        </Stack>
        {body}
      </Stack>
    </Container>
  )
}
