import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { CampfireReducer } from "./CampfireRedux";
import { ReportReducer } from "./ReportRedux";
import { JournalReducer } from "./JournalRedux";
import { CoreReducer } from "./CoreRedux";
import { ListReducer } from "./ListRedux";
import { ALL_LIST_KEY } from "./ListModel";

export const store = configureStore({
  reducer: {
    core: CoreReducer,
    campfire: CampfireReducer,
    journal: JournalReducer,
    report: ReportReducer,
    list: ListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["core"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        // Ignore these paths in the state
        ignoredPaths: ["core"],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const CoreSelector = {
  user: (state: RootState) => state.core.user,
  accessToken: (state: RootState) => state.core.accessToken,
  dateISO: (state: RootState) => state.core.dateISO,
  homeScreenIndex: (state: RootState) => state.core.homeScreenIndex,
};

export const CampfireSelector = {
  dayInfos: (state: RootState) => state.campfire.dayInfos,
  questions: (state: RootState) => state.campfire.questions,
  dayInfo: (dateISO: string) => (state: RootState) =>
    state.campfire.dayInfos[dateISO],
  question: (id: string) => (state: RootState) => state.campfire.questions[id],
  responses: (state: RootState) => state.campfire.responses,
  questionApiState: (dateISO: string) => (state: RootState) =>
    state.campfire.questionApiState[dateISO],
  responseApiState: (dateISO: string) => (state: RootState) =>
    state.campfire.responseApiState[dateISO],
};

export const ReportSelector = {
  users: (state: RootState) => state.report.users,
  questions: (state: RootState) => state.report.questions,
  responses: (state: RootState) => state.report.responses,
  reports: (state: RootState) => state.report.reports,
};

export const JournalSelector = {
  personalJournal: (state: RootState) => state.journal.personalJournal,
  entry: (dateISO: string) => (state: RootState) =>
    state.journal.entriesByDate[dateISO],
  apiState: (dateISO: string) => (state: RootState) =>
    state.journal.apiStateByDate[dateISO],
};

export const ListSelector = {
  lists: () => (state: RootState) => state.list.list,
  items: () => (state: RootState) => state.list.item,
  listApiState: (state: RootState) => state.list.apiStateByListId[ALL_LIST_KEY],
  listItemsApiState: (listId: string) => (state: RootState) =>
    state.list.apiStateByListId[listId],
};
