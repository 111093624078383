import { ItemModel, ListFrontendModel } from "../SharedModel";
import { ApiStateMap } from "./SharedModels";

type ListMap = {
  [key: string]: ListFrontendModel | undefined;
};
type ItemMap = {
  [key: string]: ItemModel | undefined;
};

export interface ListState {
  list: ListMap;
  item: ItemMap;
  apiStateByListId: ApiStateMap;
}

export const ALL_LIST_KEY = "ALL_LIST_KEY";
