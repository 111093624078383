import { useState } from "react"
import {
  DailyQuestionModel,
  ResponseDisplayModel,
} from "../../../common/SharedModel"
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy"
import React from "react"
import { Paragraphs } from "../../common/Paragraphs"
import { ApiUtil } from "../../../common_not/ApiUtil"
import { ReportApi } from "../../../common/api/ReportApi"

interface Props {
  question: DailyQuestionModel
  response: ResponseDisplayModel
  onClose: () => void
}

export const ReportPanel = ({ question, response, onClose }: Props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [reason, setReason] = useState("")

  const onUpdateReason = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event.target.value)
  }

  const onClickSubmit = async () => {
    setLoading(true)
    setError("")
    const res = await ApiUtil.ApiWrap(
      ReportApi.submitReport(question.id, response.id, reason),
    )
    if (res.success === true) {
      setSuccess("Successfully submitted report")
    } else {
      setError(res.error)
    }
    setLoading(false)
  }

  if (success) {
    return (
      <React.Fragment>
        <DialogTitle>Report Response</DialogTitle>
        <DialogContent>
          <Alert color="success">{success}</Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }

  let disabled = loading || !reason
  return (
    <React.Fragment>
      <DialogTitle>Report Response</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Stack>
            <FormLabel>Question</FormLabel>
            <Typography level="body-sm">
              <Paragraphs text={question.questionText} />
            </Typography>
          </Stack>
          <Stack>
            <FormLabel>Response</FormLabel>
            <Typography level="body-sm">
              <Paragraphs text={response.questionAnswer} />
            </Typography>
          </Stack>
          <Stack>
            <FormLabel>Reason</FormLabel>
            <Typography level="body-sm" paddingBottom={1}>
              Please provide as much information as you can about why you are
              reporting this post. We will review the response, your reason, and
              take appropriate action.
            </Typography>
            <Textarea
              onChange={onUpdateReason}
              value={reason}
              variant="outlined"
              placeholder="Explain your reason here"
            />
          </Stack>
          {error && <Alert color="danger">{error}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={loading}>
          Close
        </Button>
        <Button onClick={onClickSubmit} loading={loading} disabled={disabled}>
          Submit
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}
