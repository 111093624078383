import { AxiosError } from "axios"

//NOT THE SAME
export const GenerateApiBase = () => {
  if (import.meta.env.PROD) {
    return "https://duvalaki.app/api/"
  }
  return "https://localhost:7219/api/"
}

export interface TokenResponse {
  token: string | null
}
export class ApiUtil {
  static ErrorResponse(error: any) {
    if (error instanceof AxiosError) {
      let errorResponse = ""
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorResponse = `Bad Req: ${error.response.status}, ${error.response.data}`
        errorResponse = error.response.data
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorResponse = `No Response`
      } else {
        // Something happened in setting up the request that triggered an Error
        errorResponse = `Set up error: ${error.message}`
      }
      return errorResponse
    } else {
      throw error
    }
  }

  static async ApiWrap<T>(
    func: Promise<T>,
  ): Promise<T | { success: false; error: string }> {
    try {
      return await func
    } catch (e) {
      return { success: false, error: ApiUtil.ErrorResponse(e) }
    }
  }

  static TokenKey = "Startpoint_LocalStorage_TokenKey"
  static SaveApiToken(apiToken: string) {
    localStorage.setItem(this.TokenKey, apiToken)
  }
  static GetApiToken(): TokenResponse {
    return {
      token: localStorage.getItem(this.TokenKey),
    }
  }
  static RemoveApiToken() {
    return localStorage.removeItem(this.TokenKey)
  }
}
