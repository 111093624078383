import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../common/state/hooks";
import { CoreSelector, ListSelector } from "../common/state/store";
import { ALL_LIST_KEY } from "../common/state/ListModel";
import { Alert, Button, CircularProgress, Stack, Typography } from "@mui/joy";
import { getItemsThunk, getListsThunk } from "../common/state/ListThunk";
import { ApiUtil } from "../common_not/ApiUtil";
import { ListApi } from "../common/api/ListApi";
import { CompleteType, ListType } from "../common/SharedEnum";
import { ListActions } from "../common/state/ListRedux";
import { ItemModel, ListFrontendModel } from "../common/SharedModel";
import { ObjUtil } from "../common/ObjUtil";
import React from "react";
import { AuthDialog } from "./AuthDialog";

export const ListHome = () => {
  const user = useAppSelector(CoreSelector.user);

  return (
    <React.Fragment>
      {!user && <AuthDialog />}
      <InternalHome />
    </React.Fragment>
  );
};

const InternalHome = () => {
  const dispatch = useAppDispatch();
  const lists = useAppSelector(ListSelector.lists());
  const items = useAppSelector(ListSelector.items());
  const listApiState = useAppSelector(ListSelector.listApiState);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const loadLists = () => {
    dispatch(getListsThunk({ replace: false }));
  };

  const loadItems = (listId: string) => () => {
    dispatch(getItemsThunk({ listId, replace: false }));
  };

  const createList = async (listName: string) => {
    setLoading(true);
    setError("");
    const res = await ApiUtil.ApiWrap(
      ListApi.createList(listName, ListType.Unordered, CompleteType.Once)
    );
    if (res.success) {
      dispatch(
        ListActions.update({
          lists: [res.response],
        })
      );
    } else {
      setError(res.error);
    }
    setLoading(false);
  };

  const createItem = async (listId: string, itemText: string) => {
    setLoading(true);
    setError("");
    const res = await ApiUtil.ApiWrap(ListApi.createItem(listId, itemText));
    if (res.success) {
      dispatch(
        ListActions.update({
          items: [res.response],
        })
      );
    } else {
      setError(res.error);
    }
    setLoading(false);
  };

  const deleteList = async (list: ListFrontendModel) => {
    setLoading(true);
    setError("");
    const res = await ApiUtil.ApiWrap(ListApi.deleteList(list.id));
    if (res.success) {
      dispatch(
        ListActions.delete({
          lists: [list],
        })
      );
    } else {
      setError(res.error);
    }
    setLoading(false);
  };

  const deleteItem = async (list: ListFrontendModel, item: ItemModel) => {
    setLoading(true);
    setError("");
    const res = await ApiUtil.ApiWrap(ListApi.deleteItem(list.id, item.id));
    if (res.success) {
      dispatch(
        ListActions.delete({
          items: [item],
        })
      );
    } else {
      setError(res.error);
    }
    setLoading(false);
  };

  if (listApiState) {
    const { loading, error } = listApiState;

    if (loading) {
      return <CircularProgress />;
    } else if (error) {
      return (
        <Stack>
          <Alert color="danger">{error}</Alert>
        </Stack>
      );
    }
  }

  let listViews = [];
  for (let list of ObjUtil.values(lists)) {
    let itemViews = [];
    for (let item of ObjUtil.values(items)) {
      if (item.listId === list.id) {
        itemViews.push(
          <React.Fragment key={item.id}>
            <Stack paddingLeft={2} direction={"row"} spacing={2}>
              <Typography>{item.id + " " + item.text}</Typography>
              <Button onClick={() => deleteItem(list, item)}>
                Delete Item
              </Button>
            </Stack>
          </React.Fragment>
        );
      }
    }
    listViews.push(
      <React.Fragment key={list.id}>
        <Stack paddingLeft={1} direction={"row"} spacing={2}>
          <Typography>{list.id + " " + list.name}</Typography>
          <Button onClick={loadItems(list.id)}>Load Items</Button>
          <Button onClick={() => createItem(list.id, "Test Item")}>
            Create Item
          </Button>
          <Button onClick={() => deleteList(list)}>Delete List</Button>
        </Stack>
        {itemViews}
      </React.Fragment>
    );
  }

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} spacing={2}>
        <Typography>Create List</Typography>
        <Button onClick={() => createList("Test List")}>Create Lists</Button>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography>Lists</Typography>
        <Button onClick={loadLists}>Load Lists</Button>
      </Stack>
      {listViews}
    </Stack>
  );
};
