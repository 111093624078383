import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JournalEntryModel, PersonalJournalModel } from "../SharedModel";
import { JournalState } from "./JournalModel";
import { ApiState } from "./SharedModels";

const initialState: JournalState = {
  entriesByDate: {},
  apiStateByDate: {},
};

interface UpdatePayload {
  personalJournal?: PersonalJournalModel;
  entries?: JournalEntryModel[];
  apiState?: ApiState[];
}

interface ClearPayload {
  dateISO: string;
}

export const JournalSlice = createSlice({
  name: "journal",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<UpdatePayload>) => {
      const { personalJournal, entries, apiState } = action.payload;
      if (personalJournal) {
        state.personalJournal = personalJournal;
      }
      if (entries) {
        for (let x of entries) {
          state.entriesByDate[x.dateISO] = x;
        }
      }
      if (apiState) {
        for (let x of apiState) {
          state.apiStateByDate[x.dateISO] = x;
        }
      }
    },
    clearJournal: (state, action: PayloadAction<ClearPayload>) => {
      const { dateISO } = action.payload;
      const entry = state.entriesByDate[dateISO];
      if (entry !== undefined) {
        delete state.entriesByDate[entry.dateISO];
        // for (const response of ObjUtil.values(state.questions)) {
        //   delete state.responses[response.id]
        // }
      }
    },
    reset: (state) => {
      state.personalJournal = undefined;
      state.entriesByDate = {};
      state.apiStateByDate = {};
    },
    // delete: (state, action: PayloadAction<UpdatePayload>) => {
    //   const { responses, questions } = action.payload
    //   if (responses) {
    //     for (let x of responses) {
    //       delete state.responses[x.id]
    //     }
    //   }
    //   if (questions) {
    //     for (let x of questions) {
    //       delete state.questions[x.dateISO]
    //     }
    //   }
    // },
  },
});

export const JournalActions = JournalSlice.actions;
export const JournalReducer = JournalSlice.reducer;
