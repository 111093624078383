import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUtil } from "../../common_not/ApiUtil";
import { ALL_LIST_KEY } from "./ListModel";
import { ListApi } from "../api/ListApi";
import { ListActions } from "./ListRedux";

export const getListsThunk = createAsyncThunk(
  "list/getListsThunk",
  async (payload: {}, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(
      ListActions.update({
        apiState: [
          {
            dateISO: ALL_LIST_KEY,
            loading: true,
            sucess: false,
            error: "",
          },
        ],
      })
    );
    const res = await ApiUtil.ApiWrap(ListApi.getLists());
    console.log(res);
    if (res.success) {
      dispatch(
        ListActions.update({
          lists: res.response.lists,
          apiState: [
            {
              dateISO: ALL_LIST_KEY,
              loading: false,
              sucess: true,
              error: "",
            },
          ],
        })
      );
    } else {
      dispatch(
        ListActions.update({
          apiState: [
            {
              dateISO: ALL_LIST_KEY,
              loading: false,
              sucess: false,
              error: res.error,
            },
          ],
        })
      );
    }
  }
);

interface Payload {
  listId: string;
  replace: boolean;
}
export const getItemsThunk = createAsyncThunk(
  "list/getListsThunk",
  async (payload: Payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { listId, replace } = payload;

    if (replace) {
      dispatch(ListActions.clear({ listId }));
    }

    dispatch(
      ListActions.update({
        apiState: [
          {
            dateISO: listId,
            loading: true,
            sucess: false,
            error: "",
          },
        ],
      })
    );
    const res = await ApiUtil.ApiWrap(ListApi.getItems(listId));
    if (res.success) {
      dispatch(
        ListActions.update({
          items: res.response.items,
          apiState: [
            {
              dateISO: listId,
              loading: false,
              sucess: true,
              error: "",
            },
          ],
        })
      );
    } else {
      dispatch(
        ListActions.update({
          apiState: [
            {
              dateISO: listId,
              loading: false,
              sucess: false,
              error: res.error,
            },
          ],
        })
      );
    }
  }
);
