import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../common/state/hooks"
import { CampfireSelector, CoreSelector } from "../../../common/state/store"
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Alert,
  CircularProgress,
  Stack,
  Typography,
  accordionDetailsClasses,
  accordionSummaryClasses,
} from "@mui/joy"
import { DateUtils } from "../../../common/DateUtils"
import { Paragraphs } from "../../common/Paragraphs"
import { questionThunk } from "../../../common/state/CampfireThunk"
import { DailyQuestionReduxModel } from "../../../common/ModelExtended"
import { NoQuestionView } from "./NoQuestionView"

interface IQuestionView {
  date: Date
  isToday: boolean
  isMinDate: boolean
}
export const QuestionView = ({ date }: IQuestionView) => {
  const dateISO = DateUtils.DateISO(date)
  const dispatch = useAppDispatch()
  const user = useAppSelector(CoreSelector.user)
  const dayInfo = useAppSelector(CampfireSelector.dayInfo(dateISO))
  const question = useAppSelector(
    CampfireSelector.question(dayInfo?.questionId || ""),
  )
  const apiState = useAppSelector(CampfireSelector.questionApiState(dateISO))

  useEffect(() => {
    if (apiState === undefined) {
      dispatch(questionThunk({ dateISO, replace: false }))
    }
  }, [apiState])

  if (!date || !user || apiState === undefined) {
    return <div />
  }

  const { loading, error } = apiState

  let body
  if (loading) {
    body = (
      <Stack alignItems={"center"} padding={5}>
        <CircularProgress />
      </Stack>
    )
  } else if (error) {
    body = <Alert color="danger">{error}</Alert>
  } else if (question) {
    body = (
      <Stack paddingBottom={6}>
        <QuestionDisplay question={question} />
      </Stack>
    )
  } else {
    body = (
      <Stack paddingTop={1}>
        <NoQuestionView />
      </Stack>
    )
  }

  return body
}

interface QuestionDisplayProps {
  question: DailyQuestionReduxModel
}
export const QuestionDisplay = ({ question }: QuestionDisplayProps) => {
  const [expanded, setExpanded] = useState(false)

  let body
  if (question.questionExtension) {
    body = (
      <AccordionGroup
        sx={{
          [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
            {
              paddingBlock: "0rem",
              padding: 0,
            },
          [`& .${accordionSummaryClasses.button}`]: {
            paddingBlock: "0rem",
            padding: 0,
            paddingBottom: 1,
            borderRadius: 8,
          },
        }}
      >
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary sx={{}} indicator={null}>
            <Stack spacing={1}>
              <Typography level="h1" fontWeight={"md"}>
                <Paragraphs text={question.questionText} />
              </Typography>
              {!expanded && (
                <Typography level="body-sm">
                  If you'd like, click to dig deep into this question
                </Typography>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails onClick={() => setExpanded(!expanded)}>
            <Typography level="body-md">
              <Paragraphs text={question.questionExtension} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    )
  } else {
    body = (
      <Typography level="h1" fontSize={40} fontWeight={"md"} paddingBottom={6}>
        <Paragraphs text={question.questionText} />
      </Typography>
    )
  }
  return body
}
