import { createBrowserRouter } from "react-router-dom";
import { DebugHome } from "../DebugHome";
import { DesktopHome } from "../DesktopHome";
import { ReportHome } from "../report/ReportHome";
import { ListHome } from "../ListHome";

export enum ViewMode {
  DailyQuestion = 1,
  PersonalJournal = 2,
}

export enum DaysViewMode {
  Day = 1,
  Week = 2,
  Month = 3,
}

export const Router = createBrowserRouter([
  {
    path: "/",
    element: (
      <DesktopHome
        viewMode={ViewMode.DailyQuestion}
        daysViewMode={DaysViewMode.Day}
      />
    ),
  },
  {
    path: "/question/month/:date/",
    element: (
      <DesktopHome
        viewMode={ViewMode.DailyQuestion}
        daysViewMode={DaysViewMode.Month}
      />
    ),
  },
  {
    path: "/journal/month/:date/",
    element: (
      <DesktopHome
        viewMode={ViewMode.PersonalJournal}
        daysViewMode={DaysViewMode.Month}
      />
    ),
  },
  {
    path: "/question/week/:date/",
    element: (
      <DesktopHome
        viewMode={ViewMode.DailyQuestion}
        daysViewMode={DaysViewMode.Week}
      />
    ),
  },
  {
    path: "/journal/week/:date/",
    element: (
      <DesktopHome
        viewMode={ViewMode.PersonalJournal}
        daysViewMode={DaysViewMode.Week}
      />
    ),
  },
  {
    path: "/question/:date/",
    element: <DesktopHome viewMode={ViewMode.DailyQuestion} />,
  },
  {
    path: "/journal/:date/",
    element: <DesktopHome viewMode={ViewMode.PersonalJournal} />,
  },
  {
    path: "/debug",
    element: <DebugHome />,
  },
  {
    path: "/lists",
    element: <ListHome />,
  },
  {
    path: "/reports",
    element: <ReportHome />,
  },
  {
    path: "/:date",
    element: <DesktopHome viewMode={ViewMode.DailyQuestion} />,
  },
]);

interface createUrlPayload {
  dateISO: string;
  viewMode: ViewMode;
  daysViewMode: DaysViewMode;
}
export const createURL = ({
  dateISO,
  viewMode,
  daysViewMode,
}: createUrlPayload) => {
  let daysViewString = "";
  if (daysViewMode === DaysViewMode.Week) {
    daysViewString = "week/";
  } else if (daysViewMode === DaysViewMode.Month) {
    daysViewString = "month/";
  }

  if (viewMode === ViewMode.DailyQuestion) {
    return `/question/${daysViewString}${dateISO}`;
  } else if (viewMode === ViewMode.PersonalJournal) {
    return `/journal/${daysViewString}${dateISO}`;
  } else {
    return `/${dateISO}`;
  }
};
