import { useState } from "react"
import { useDispatch } from "react-redux"
import { ApiUtil } from "../../common_not/ApiUtil"
import { ReportApi } from "../../common/api/ReportApi"
import { ReportActions } from "../../common/state/ReportRedux"
import {
  Container,
  Typography,
  Stack,
  FormLabel,
  Input,
  Button,
  Alert,
  CardContent,
  Card,
} from "@mui/joy"
import { useAppSelector } from "../../common/state/hooks"
import { ReportSelector } from "../../common/state/store"
import {
  DailyQuestionModel,
  ReportModel,
  ResponseDBModel,
  UserModel,
} from "../../common/SharedModel"
import { ObjUtil } from "../../common/ObjUtil"
import React from "react"
import { Paragraphs } from "../common/Paragraphs"

export const ReportHome = () => {
  const dispatch = useDispatch()
  const [passcode, setPasscode] = useState("")
  const [passcodeSet, setPasscodeSet] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const onClickSetPasscode = () => {
    getReports()
  }

  const getReports = async () => {
    setLoading(true)
    setError("")
    var response = await ApiUtil.ApiWrap(ReportApi.getAllReports(passcode))
    if (response.success) {
      dispatch(
        ReportActions.update({
          users: response.response.users,
          questions: response.response.questions,
          responses: response.response.responses,
          reports: response.response.reports,
        }),
      )
      setPasscodeSet(true)
    } else {
      setError(response.error)
    }
    setLoading(false)
  }

  const onPassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasscode(event.target.value)
  }

  return (
    <Container>
      <Typography level="h1">Report Page</Typography>
      <Stack spacing={2}>
        <FormLabel>Passcode</FormLabel>
        <Stack direction={"row"} spacing={1}>
          <Input value={passcode} onChange={onPassChange} disabled={loading} />
          <Button onClick={onClickSetPasscode} loading={loading}>
            Set Passcode
          </Button>
        </Stack>
        {error && <Alert color="danger">{error}</Alert>}
        {passcodeSet && <ReportList passcode={passcode} />}
      </Stack>
    </Container>
  )
}

const ReportList = ({ passcode }: { passcode: string }) => {
  const users = useAppSelector(ReportSelector.users)
  const questions = useAppSelector(ReportSelector.questions)
  const responses = useAppSelector(ReportSelector.responses)
  const reports = useAppSelector(ReportSelector.reports)

  let entries = []
  for (let report of ObjUtil.values(reports)) {
    let question = questions[report.questionId]
    let reportingUser = users[report.userId]
    let response = responses[report.responseId]
    let postingUser = response ? users[report.userId] : undefined

    entries.push(
      <ReportEntry
        key={report.id}
        passcode={passcode}
        report={report}
        question={question}
        response={response}
        reportingUser={reportingUser}
        postingUser={postingUser}
      />,
    )
  }

  return <React.Fragment>{entries}</React.Fragment>
}

interface ReportEntryProps {
  passcode: string
  report: ReportModel
  question?: DailyQuestionModel
  response?: ResponseDBModel
  postingUser?: UserModel
  reportingUser?: UserModel
}
const ReportEntry = ({
  passcode,
  report,
  question,
  response,
  postingUser,
  reportingUser,
}: ReportEntryProps) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const onDeleteClick = (deletePost: boolean) => async () => {
    setLoading(true)
    setError("")
    var response = await ApiUtil.ApiWrap(
      ReportApi.deleteReport(passcode, deletePost, report.id),
    )
    if (response.success) {
      dispatch(
        ReportActions.delete({
          reports: [report],
        }),
      )
    } else {
      setError(response.error)
      setLoading(false)
    }
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={1}>
          <Stack direction={"row"} spacing={2}>
            <Stack>
              <FormLabel>Posting User</FormLabel>
              {postingUser ? (
                <Typography>
                  {postingUser.displayName}
                  <br />
                  {postingUser.email}
                </Typography>
              ) : (
                <Typography>N/A</Typography>
              )}
            </Stack>
            <Stack>
              <FormLabel>Reporting User</FormLabel>
              {reportingUser ? (
                <Typography>
                  {reportingUser.displayName}
                  <br />
                  {reportingUser.email}
                </Typography>
              ) : (
                <Typography>N/A</Typography>
              )}
            </Stack>
          </Stack>
          <Stack>
            <FormLabel>Question</FormLabel>
            {question ? (
              <Typography>
                <Paragraphs text={question.questionText} />
              </Typography>
            ) : (
              <Typography>N/A</Typography>
            )}
          </Stack>
          <Stack>
            <FormLabel>Response</FormLabel>
            {response ? (
              <Typography>
                <Paragraphs text={response.questionAnswer} />
              </Typography>
            ) : (
              <Typography>N/A</Typography>
            )}
          </Stack>
          <Stack>
            <FormLabel>Reason</FormLabel>
            <Typography>
              <Paragraphs text={report.reason} />
            </Typography>
          </Stack>
          {error && <Alert color="danger">{error}</Alert>}
          <Stack direction={"row"} spacing={2}>
            <Button loading={loading} onClick={onDeleteClick(true)}>
              Delete Post
            </Button>
            <Button loading={loading} onClick={onDeleteClick(false)}>
              Delete Report
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
