export enum ResponseShareType {
  Personal = 100,
  Public = 300,
}

export enum ListType {
  Ordered = 100,
  Unordered = 200,
}

export enum CompleteType {
  Once = 100,
  Recurring = 200,
}

export enum ListAccessType {
  Owner = 100,
  Editor = 200,
}

export const EnumUtils = {
  ToResponseShareType: (str: string) => {
    let num = parseInt(str);
    switch (num) {
      case ResponseShareType.Personal:
        return ResponseShareType.Personal;
      case ResponseShareType.Public:
      default:
        return ResponseShareType.Public;
    }
  },
};
