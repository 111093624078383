import { Typography } from "@mui/joy"
import React from "react"

export const Paragraphs = ({ text }: { text: string }) => {
  var str = text.split(/(?:\r\n|\r|\n)/g)
  return (
    <React.Fragment>
      <Typography>
        {str.map(function (item, idx) {
          return (
            <span key={idx}>
              {item}
              <br />
            </span>
          )
        })}
      </Typography>
    </React.Fragment>
  )
}
