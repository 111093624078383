import { Button, Stack, Typography } from "@mui/joy"
import HistoryEduIcon from "@mui/icons-material/HistoryEdu"

interface Props {
  onClickTemplates: () => void
}

export const JournalIntroView = ({ onClickTemplates }: Props) => {
  return (
    <Stack spacing={2} alignItems={"center"}>
      <HistoryEduIcon sx={{ fontSize: 70 }} />
      <Typography level="h1" fontSize={40} fontWeight={"md"}>
        Personal Journal
      </Typography>
      <Typography level="body-md" textAlign={"center"}>
        Your personal journal is your space to delve into whatever feelings or
        thoughts.
        <br />
        Create your own templates or use our defaults.
        <br />
        Select a template and write whatever you'd like.
      </Typography>
      <Stack direction={"row"} spacing={4}>
        <Button onClick={onClickTemplates}>Create My Journal</Button>
      </Stack>
    </Stack>
  )
}
